<template>
  <portal to="fav" :order="2" v-if="visible">
    <div class="preview-report-comp">
      <div class="iframe-container">
        <iframe
          v-if="_isMobile"
          :src="src"
          allowfullscreen
          id="myIframe"
        ></iframe>
        <div v-else>
          <ana-report @back="reportPopupVisible = false" />
        </div>
      </div>
      <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />
    </div>
  </portal>
</template>

<script>
import AnaReport from '../../pages/Ana/pages/analysis/pages/anaReport/index'
export default {
  components: { AnaReport },
  name: 'preview-report',
  props: {
    reportId: [String, Number],
    visible: {
      type: Boolean,
      default: _ => false
    },
    aspectRadio: String
  },
  data() {
    return { isPhone: false }
  },
  computed: {
    src() {
      // return `http://192.168.2.128:3000/shared/report/${this.reportId}`
      return `https://tool.farbun.com/shared/report/${this.reportId}`
    },
    _isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  },
  methods: {
    onClickLeft() {
      this.$emit('back')
    }
  }
}
</script>

<style lang="stylus">
.preview-report-comp
  z-index 3001
  position fixed
  top 0
  left 0
  background #fff
  width 100vw
  height 100vh
  .iframe-container
    height calc(100vh - 45px)
    width 100vw
    position relative
    #myIframe
      position absolute
      left 0
      top 0
      width 100vw
      height 100%
</style>
