<template>
  <div class="semantic-section">
    <section-panel :title="attr.name" :loading="loading" @checkAll="checkAll">
      <div class="summary-name">{{ list.name }}</div>
      <ve-ring
        ref="chart"
        class="ring-chart"
        :width="chartWidth"
        :height="chartHeight"
        :judge-width="true"
        :extend="chartExtend"
        :data="chartData"
        :settings="chartSettings"
        v-if="list && list.num"
      ></ve-ring>
    </section-panel>
  </div>
</template>

<script>
import VeRing from 'v-charts/lib/ring.common'
import SectionPanel from './sectionPanel'
export default {
  name: 'semantic-section',
  props: {
    isDeleted: Boolean,
    loading: Boolean,
    list: [Array, Object],
    attr: Object
  },
  components: {
    SectionPanel,
    VeRing
  },
  data() {
    return {}
  },
  computed: {
    // 页面缩放比例
    pr() {
      const fontSize =
        parseFloat(
          document.documentElement.style.fontSize.replace(/px/g, '')
        ) || 14
      return fontSize / 37.5
    },
    chartSettings() {
      return {
        label: {
          fontSize: 14 * this.pr
        },
        offsetY: document.body.clientWidth * 0.25,
        radius: ['48%', '64%']
      }
    },
    chartExtend() {
      return {
        textStyle: {
          fontSize: 14 * this.pr
        },
        legend: {
          show: false,
          textStyle: {
            fontSize: 14 * this.pr
          }
        }
      }
    },
    chartData() {
      if (this.list && this.list.num && this.list.title) {
        const d = this.list
        const total = d.num.reduce((v, c) => v + c)
        const rows = d.num.map((num, i) => {
          const pr = parseFloat(((num / total) * 100).toFixed(2))
          return {
            type: `${d.title[i]} ${pr}%`,
            num
          }
        })
        return {
          columns: ['type', 'num'],
          rows
        }
      } else {
        return {
          columns: ['type', 'num'],
          rows: []
        }
      }
    },
    chartWidth() {
      return document.body.clientWidth + 'px'
    },
    chartHeight() {
      return document.body.clientWidth * 0.6 + 'px'
    }
  },
  methods: {
    checkAll(payload) {
      const p = {
        isDeleted: this.isDeleted,
        type: this.attr.type
      }
      this.$emit('checkAll', p)
    }
  },
  watch: {
    list(nv, ov) {
      if (nv.num && nv.title) {
        this.$nextTick(_ => {
          this.$refs.chart.echarts.resize()
        })
      }
    }
  }
}
</script>

<style lang="stylus">
.semantic-section
  width 100vw
  & .summary-name
    text-align center
    font-size 15px
    padding 10px 0 0 0
    font-weight bold
    color #444
  & .ring-chart
    margin-top 20px
    width 100vw
</style>
