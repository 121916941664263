var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "report", staticClass: "ana-report-page" },
    [
      _c("van-nav-bar", {
        attrs: { "left-text": "返回", "left-arrow": "" },
        on: { "click-left": _vm.onClickLeft, "click-right": _vm.onClickRight },
      }),
      _c(
        "van-tabs",
        {
          staticClass: "no-tab-title",
          attrs: { swipeable: "", animated: "" },
          model: {
            value: _vm.activeTabName,
            callback: function ($$v) {
              _vm.activeTabName = $$v
            },
            expression: "activeTabName",
          },
        },
        [
          _c(
            "van-tab",
            { attrs: { name: "use" } },
            [
              _c("tab-wrap", {
                attrs: {
                  queryStatus: _vm.queryStatus,
                  basicDeleted: _vm.basicDeleted,
                  listData: _vm.listData,
                  savedReportData: _vm.savedReportData,
                  userInfo: _vm.userData,
                  lawyerSuggestion: _vm.lawyerSuggestion,
                  today: _vm.today,
                  shareUrl: _vm.shareUrl,
                },
                on: {
                  change: _vm.handleChange,
                  checkAll: _vm.handleCheckAll,
                  createReport: _vm.createReport,
                  shareReport: _vm.shareReport,
                  dowloadReport: _vm.dowloadReport,
                },
              }),
            ],
            1
          ),
          _c(
            "van-tab",
            { attrs: { name: "delete" } },
            [
              _c("tab-wrap", {
                attrs: {
                  isDeleted: true,
                  basicDeleted: _vm.basicDeleted,
                  listData: _vm.listDataDeleted,
                  queryStatus: _vm.queryStatus,
                },
                on: {
                  change: _vm.handleChangeFromDeleted,
                  checkAll: _vm.handleCheckAll,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("fb-notify", {
        attrs: { msg: _vm.actionNotifyMsg, visible: _vm.actionNotifyVisible },
        on: { linkClick: _vm.onNotifyLinkClick },
      }),
      _vm.favVisible
        ? _c(
            "portal",
            { attrs: { to: "fav", order: 1 } },
            [
              _c("transition", { attrs: { name: "van-slide-down" } }, [
                _c(
                  "div",
                  { staticClass: "full-screen-portal-comp" },
                  [
                    _c("skyDrive", {
                      ref: "skyDrive",
                      attrs: {
                        listid: _vm.favFolderId,
                        Type: "REPORT",
                        modulename: "分析报告",
                        listname: null,
                        summary: _vm.summary,
                      },
                      on: { CancelCollect: _vm.cancelFav, getId: _vm.useFavId },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.aboveDataLoaded,
              expression: "aboveDataLoaded",
            },
          ],
          staticClass: "create-report-btn",
        },
        [
          _c(
            "div",
            { staticClass: "share" },
            [
              _c(
                "van-button",
                {
                  staticClass: "shareBtn",
                  attrs: {
                    type: "default",
                    "data-clipboard-text": _vm.shareUrl,
                    plain: "",
                    hairline: "",
                    block: "",
                  },
                  on: { click: _vm.shareReport },
                },
                [
                  _c("van-icon", {
                    attrs: { color: "#249eff", name: "share" },
                  }),
                  _vm._v(" 转发到微信"),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "fav" },
            [
              _c(
                "van-button",
                {
                  attrs: {
                    type: "default",
                    block: "",
                    plain: "",
                    hairline: "",
                  },
                  on: { click: _vm.createReport },
                },
                [
                  _c("van-icon", { attrs: { name: "star", color: "#249eff" } }),
                  _vm._v("收藏到网盘"),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }