<template>
  <div class="report-header-comp">
    <div class="title">
      案情分析报告
    </div>
    <div class="info">
      <div class="law-firm">
        {{ (userInfo.organization && userInfo.organization.name) || '' }}
      </div>
      <div class="lawye">{{ userInfo.nickName }}</div>
      <div class="time">{{ today }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'report-header',
  props: {
    // 律师信息
    userInfo: Object,
    today: String
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {}
}
</script>

<style lang="stylus" scoped>
.report-header-comp
  position relative
  padding-bottom 6px
  border-bottom 5px solid #FF0000
  & .title
    color #FF0000
    font-weight bold
    font-size 28px
    text-align center
    height 40px
    line-height 40px
  & .info
    display flex
    justify-content flex-end
    font-size 14px
    font-weight bold
    & div
      padding 0 8px
</style>
