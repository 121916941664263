<template>
  <div class="report-summary-comp">
    <section-panel :title="title" @checkAll="checkAll">
      <div class="ana-text">
        <van-field
          v-model="copyText"
          placeholder="案情概要"
          type="textarea"
          autosize
          @change="change"
        />
      </div>
    </section-panel>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SectionPanel from './sectionPanel'
export default {
  name: 'report-basic',
  props: {
    isDeleted: Boolean
  },
  components: {
    SectionPanel
  },
  data() {
    return {
      copyText: '',
      title: '案情概要'
    }
  },
  computed: {
    ...mapState('ana', ['text'])
  },
  methods: {
    ...mapMutations('ana', ['SET_TEXT']),
    // 全选
    checkAll(payload) {
      const p = {
        isDeleted: this.isDeleted,
        type: 'basic'
      }
      this.$emit('checkAll', p)
    },
    change() {
      this.SET_TEXT(this.copyText)
    }
  },
  created() {
    this.copyText = this.text
  },
  mounted() {}
}
</script>

<style lang="stylus" scoped>
.report-summary-comp
  position relative
  & .ana-text
    padding 4px
    margin 0 6px
    & span
      font-size 14px
      line-height 24px
      color #333
</style>
