var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-law-section-comp" },
    [
      _c(
        "section-panel",
        {
          attrs: { title: _vm.attr.name, loading: _vm.loading },
          on: { checkAll: _vm.checkAll },
        },
        [
          _c(
            "div",
            { staticClass: "cell-list" },
            _vm._l(_vm.list, function (c) {
              return _c("law-cell", {
                key: c.id,
                attrs: {
                  isDeleted: _vm.isDeleted,
                  cellData: c,
                  type: _vm.attr.type,
                },
                on: { change: _vm.onChange },
              })
            }),
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }