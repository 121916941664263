var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-header-comp" }, [
    _c("div", { staticClass: "title" }, [_vm._v(" 案情分析报告 ")]),
    _c("div", { staticClass: "info" }, [
      _c("div", { staticClass: "law-firm" }, [
        _vm._v(
          " " +
            _vm._s(
              (_vm.userInfo.organization && _vm.userInfo.organization.name) ||
                ""
            ) +
            " "
        ),
      ]),
      _c("div", { staticClass: "lawye" }, [
        _vm._v(_vm._s(_vm.userInfo.nickName)),
      ]),
      _c("div", { staticClass: "time" }, [_vm._v(_vm._s(_vm.today))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }