<template>
  <div class="law-child-comp">
    <div class="header">
      <van-checkbox :value="true" shape="square" @click="emitChange" />
      <div
        class="name"
        :style="cellData.colorType ? 'color: rgb(255, 153, 0) ' : ''"
      >
        {{ cellData.name || '' }}
      </div>
    </div>
    <div class="content">
      <template v-if="isStrContent">
        <p>{{ cellData.content || '' }}</p>
      </template>
      <template v-if="isArrContent">
        <p v-for="(item, index) in cellData.content" :key="index">
          {{ item }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'law-child',
  props: {
    type: String,
    cellData: Object
  },
  data() {
    return {}
  },
  computed: {
    isStrContent() {
      return typeof this.cellData.content === 'string'
    },
    isArrContent() {
      return Array.isArray(this.cellData.content)
    }
  },
  methods: {
    emitChange() {
      this.$emit('change', this.cellData)
    }
  }
}
</script>

<style lang="stylus" scoped>
.law-child-comp
  position relative
  margin-bottom 10px
  &:first-child
    margin-top 10px
  & .header
    display flex
    min-height 20px
    align-items flex-start
    padding 0 6px
    & .name
      flex 1
      margin-left 10px
      font-size 14px
      font-weight bold
  & .content
    margin-left 26px
    padding 6px 10px
    font-size 13px
    color #555
</style>
