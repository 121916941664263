<template>
  <div v-if="chartData" class="ana-chart-section">
    <section-panel :title="attr.name" :loading="loading" @checkAll="checkAll">
      <div class="month" v-if="chartDataMonth">
        <div class="title">时间</div>
        <ve-histogram
          :data="chartDataMonth"
          :settings="chartSettings"
          :extend="chartExtend"
          :height="chartHeight"
          :init-options="opt"
          :judge-width="true"
          :xAxis="xAxisMonth"
          :yAxis="yAxis"
        ></ve-histogram>
      </div>
      <div class="year" v-if="chartDataYear">
        <div class="title">年度</div>
        <ve-histogram
          :data="chartDataYear"
          :settings="chartSettings"
          :extend="chartExtend"
          :height="chartHeight"
          :yAxis="yAxis"
          :xAxis="xAxisYear"
          :judge-width="true"
        ></ve-histogram>
      </div>
    </section-panel>
  </div>
</template>

<script>
import SectionPanel from './sectionPanel'
import VeHistogram from 'v-charts/lib/histogram.common'

export default {
  name: 'chart-section',
  props: {
    isDeleted: Boolean,
    loading: Boolean,
    list: [Array, Object],
    attr: Object
  },
  components: {
    SectionPanel,
    VeHistogram
  },
  data() {
    return {}
  },
  computed: {
    chartData() {
      return this.list
    },
    opt() {
      return {
        textStyle: {
          fontSize: 18 // 字体大小
        }
      }
    },
    // 页面缩放比例
    pr() {
      const fontSize = parseFloat(
        document.documentElement.style.fontSize.replace(/px/g, '')
      )
      return fontSize / 37.5
    },
    chartSettings() {
      return {
        labelMap: {
          date: '时间',
          num: '篇数'
        }
      }
    },
    textStyle() {
      return {
        fontSize: 14 * this.pr
      }
    },
    xAxisMonth() {
      return [
        {
          type: 'category',
          data: this.month.title,
          axisLabel: {
            rotate: -35,
            margin: 4,
            verticalAlign: 'top',
            align: 'left',
            fontSize: 10 * this.pr
          }
        }
      ]
    },
    xAxisYear() {
      return [
        {
          type: 'category',
          data: this.year.title,
          axisLabel: { rotate: -40, align: 'left', fontSize: 10 * this.pr }
        }
      ]
    },
    yAxis() {
      return {
        axisLabel: {
          fontSize: 12 * this.pr
        }
      }
    },
    chartExtend() {
      return {
        legend: {
          textStyle: {
            fontSize: 14 * this.pr
          }
        }
      }
    },
    chartHeight() {
      return document.body.clientWidth * 0.7 + 'px'
    },
    month() {
      return this.chartData.month
    },
    year() {
      return this.chartData.year
    },
    chartDataMonth() {
      if (this.month && this.month.title) {
        const r = this.month.title.map((item, i) => {
          const t = {
            date: item,
            num: this.month.data[i]
          }
          return t
        })
        return {
          columns: ['date', 'num'],
          rows: r
        }
      } else {
        return null
      }
    },
    monthData() {
      return this.month.title
    },
    chartDataYear() {
      if (this.year && this.year.title) {
        const r = this.year.title.map((item, i) => {
          const t = {
            date: item,
            num: this.year.data[i]
          }
          return t
        })
        return {
          columns: ['date', 'num'],
          rows: r
        }
      } else {
        return null
      }
    }
  },
  methods: {
    checkAll(payload) {
      const p = {
        isDeleted: this.isDeleted,
        type: this.attr.type
      }
      this.$emit('checkAll', p)
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="stylus" scoped>
.ana-chart-section
  width 100%
  & .month, .year
    margin 15px 0
    & .title
      font-size 14px
      margin-bottom 10px
      padding 0 10px
</style>
