var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "semantic-section" },
    [
      _c(
        "section-panel",
        {
          attrs: { title: _vm.attr.name, loading: _vm.loading },
          on: { checkAll: _vm.checkAll },
        },
        [
          _c("div", { staticClass: "summary-name" }, [
            _vm._v(_vm._s(_vm.list.name)),
          ]),
          _vm.list && _vm.list.num
            ? _c("ve-ring", {
                ref: "chart",
                staticClass: "ring-chart",
                attrs: {
                  width: _vm.chartWidth,
                  height: _vm.chartHeight,
                  "judge-width": true,
                  extend: _vm.chartExtend,
                  data: _vm.chartData,
                  settings: _vm.chartSettings,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }