var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "law-child-comp" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("van-checkbox", {
          attrs: { value: true, shape: "square" },
          on: { click: _vm.emitChange },
        }),
        _c(
          "div",
          {
            staticClass: "name",
            style: _vm.cellData.colorType ? "color: rgb(255, 153, 0) " : "",
          },
          [_vm._v(" " + _vm._s(_vm.cellData.name || "") + " ")]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content" },
      [
        _vm.isStrContent
          ? [_c("p", [_vm._v(_vm._s(_vm.cellData.content || ""))])]
          : _vm._e(),
        _vm.isArrContent
          ? _vm._l(_vm.cellData.content, function (item, index) {
              return _c("p", { key: index }, [_vm._v(" " + _vm._s(item) + " ")])
            })
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }