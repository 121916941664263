var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "section-panel-comp" }, [
    _c("div", { staticClass: "panel-header" }, [
      _c(
        "div",
        { staticClass: "action" },
        [
          _c("van-checkbox", {
            attrs: { value: "true", shape: "square", disabled: _vm.isDisabled },
            on: { click: _vm.onCheck },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.title || "") + " "),
      ]),
      _vm.loading
        ? _c("div", { staticClass: "loading-status" }, [_c("van-loading")], 1)
        : _vm._e(),
    ]),
    _c("div", { staticClass: "panel-content" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }