var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.chartData
    ? _c(
        "div",
        { staticClass: "ana-chart-section" },
        [
          _c(
            "section-panel",
            {
              attrs: { title: _vm.attr.name, loading: _vm.loading },
              on: { checkAll: _vm.checkAll },
            },
            [
              _vm.chartDataMonth
                ? _c(
                    "div",
                    { staticClass: "month" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("时间")]),
                      _c("ve-histogram", {
                        attrs: {
                          data: _vm.chartDataMonth,
                          settings: _vm.chartSettings,
                          extend: _vm.chartExtend,
                          height: _vm.chartHeight,
                          "init-options": _vm.opt,
                          "judge-width": true,
                          xAxis: _vm.xAxisMonth,
                          yAxis: _vm.yAxis,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.chartDataYear
                ? _c(
                    "div",
                    { staticClass: "year" },
                    [
                      _c("div", { staticClass: "title" }, [_vm._v("年度")]),
                      _c("ve-histogram", {
                        attrs: {
                          data: _vm.chartDataYear,
                          settings: _vm.chartSettings,
                          extend: _vm.chartExtend,
                          height: _vm.chartHeight,
                          yAxis: _vm.yAxis,
                          xAxis: _vm.xAxisYear,
                          "judge-width": true,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }