<template>
  <div :style="`width:100%;height:${clientHeight}px`">
    <div class="skydrive" v-if="getlist === 1">
      <div
        :style="{
          margin: '0px auto',
          display: 'flex',
          height: '1.066667rem',
          'border-bottom': ' 1px solid #F3F4F7',
        }"
        v-if="srh"
      >
        <!-- 新建文件夹搜索框 -->
        <div class="search" style="top: 0px; border: none; left: 4%" v-if="!isSearch">
          <!-- 新建文件夹 -->
          <input type="text" @blur="shijiao" ref="sousuo" v-model="value" />
          <div class="polieer">
            <div @click="Complete" style="width: 6rem">确定</div>
            <div class="link">|</div>
            <div class="cancel" @click="quxiao" style="width: 6rem">取消</div>
          </div>
        </div>
        <!-- 搜索时候的搜索框 -->
        <div class="search" v-else>
          <i class="iconfont icon-02"></i>
          <input type="text" v-model="ssinput" ref="ssInt" @blur="blur" style="width: 80%" />
          <i class="iconfont icon-clear" style="align-self: center; margin-right: 10px" v-if="clear" @click="cleardata"></i>
        </div>
      </div>
      <!-- 标题 -->
      <div class="title" v-else>
        <div style="display: flex; justify-content: space-between; width: 91%; margin: 0px auto">
          <div v-if="!iscollect">
            <i class="iconfont icon-back" @click="back"></i>
            <span>{{ showMoveCopyCollect }}</span>
          </div>
          <div v-else class="ipt-content">
            <input type="text" ref="shoucang" placeholder="请选择文件夹" v-model="shoucang" onkeydown="if(event.keyCode===32 && event.target.value.trim().length===0){return false}" />
          </div>
          <div v-if="!isMove" :style="{ position: 'relative' }" class="icon_color">
            <!-- <i @click="$router.push({path:'/skyDriveDetail'})">
              <span style="padding:0 15px;font-style:normal;display:inline-block;border-radius:5px;color:#4b9efb;font-size:0.373333rem">图</span>

            </i> -->
            <i @click="photoModle = !photoModle">
              <span v-html="photoModle === false ? '图' : '表'" style="padding: 0 15px; font-style: normal; display: inline-block; border-radius: 5px; color: #4b9efb; font-size: 0.373333rem">图</span>
            </i>
            <i class="iconfont icon-p" @click="add"></i>
            <i class="iconfont icon-sousuo" @click="SouSuo"></i>
            <i class="iconfont icon-liebiao1" @click="scr"></i>
            <!-- 筛选文件 -->
            <ul class="screen" v-if="screen">
              <li @click="mimitype = ''" :style="{ 'font-weight': mimitype === '' ? '700' : '' }">全部</li>
              <li @click="mimitype = 'FOLDER'" :style="{ 'font-weight': mimitype === 'FOLDER' ? '700' : '' }">文件夹</li>
              <li
                @click="mimitype = 'IMAGE'"
                :style="{
                  'font-weight': mimitype === 'IMAGE' ? '700' : 'IMAGE',
                }"
              >
                图片
              </li>
              <li @click="mimitype = 'LINK'" :style="{ 'font-weight': mimitype === 'LINK' ? '700' : 'LINK' }">链接</li>
              <li
                @click="mimitype = 'LAW_FRAGMENT'"
                :style="{
                  'font-weight': mimitype === 'LAW_FRAGMENT' ? '700' : 'LAW_FRAGMENT',
                }"
              >
                判决依据
              </li>
              <li
                @click="mimitype = 'LEGAL_PRECEDENT_FRAGMENT'"
                :style="{
                  'font-weight': mimitype === 'LEGAL_PRECEDENT_FRAGMENT' ? '700' : 'LEGAL_PRECEDENT_FRAGMENT',
                }"
              >
                判例片段
              </li>
            </ul>
            <!-- 新建上传文件 -->
            <ul class="added" v-if="added">
              <li @click="upload">新建文件夹</li>
              <li @click="uploadfile">上传文件</li>
              <li @click="uploadPhoto">上传图片视频</li>
              <li>新建日程/笔记</li>
              <li
                @click="
                  getlist = 2
                  link_collect = ''
                  link_title = ''
                "
              >
                新建链接
              </li>
            </ul>
          </div>
          <div v-else style="display: flex; justify-content: space-between">
            <i class="iconfont icon-sousuo" @click="SouSuo" v-if="!(isMove && iscollect)"></i>
            <div class="newFolder" @click="moveadd">新建文件夹</div>
          </div>
        </div>
      </div>
      <div style="padding: 10px 10px 0 10px">
        <van-checkbox v-model="isShow" shape="square" icon-size="14px">隐藏空文件夹</van-checkbox>
      </div>
      <!-- 首页路径 -->
      <div class="route">
        <div class="routerlist">
          <ul ref="fq">
            <div @click="goBack(item.id, item.level)" v-for="(item, index) in dirs" :key="index">
              <span v-if="index !== 0">/</span>
              <span>{{ formDataName(item.name) }}</span>
            </div>
          </ul>
        </div>
      </div>

      <!-- 删除移动下载操作 -->
      <ul class="delete" v-if="selectItems.length > 0">
        <li @click="bootcanp">
          <checkbox v-model="operation" :cancel="cancel"></checkbox>
        </li>
        <!-- 删除移动下载操作 -->
        <li>已选{{ selectItems.length }}</li>
        <li @click="Delete" v-if="deleteBtn">删除</li>
        <li @click="move" v-if="moveBtn">移动</li>
        <li @click="copy" v-if="copyBtn">复制</li>
        <!-- <li @click="recycle" v-if="recycleBtn">移动到回收站</li> -->
        <li @click="download" v-if="downloadBtn">
          {{ isIOS ? '预览' : '下载' }}
        </li>
        <li @click="downloadZIP" v-if="downloadZIPBtn && !isIOS">打包下载</li>
        <li @click="convert((type = 'PDF'))" v-if="convertBtnPDF">转PDF</li>
        <li @click="convert((type = 'WORD'))" v-if="convertBtnWORD">转WORD</li>
        <li @click="daochu(1)" v-if="daochuBtn">导出到PDF</li>
        <li @click="daochu(0)" v-if="daochuBtn">导出到WORD</li>
        <li @click="share">分享</li>
      </ul>
      <ul class="ShanChuTop" v-if="!tpe && !isMove && !isSearch && !iscopy && !iscollect">
        <li @click="bootcanp">
          <checkbox v-model="operation" :cancel="cancel"></checkbox>
        </li>
        <li>名称</li>
      </ul>
      <fb-loading :loading="loading" v-if="loading" />
      <div class="shoucang" v-else-if="!loading && collection.length === 0">暂无</div>
      <div class="content" ref="scroller" @scroll="load">
        <ul class="Vontent">
          <!-- 循环图片模式文件夹 -->
          <template v-if="photoModle">
            <li
              style="height: 2.29334rem"
              v-for="(item, index) in collection"
              :key="index + 'info'"
              v-show="
                moveList.findIndex((itemr) => {
                  return itemr.id === item.id
                }) === -1
              "
            >
              <!-- 复选框判断 -->
              <div class="file" @click="change(item, index)">
                <checkbox v-model="item.flag"></checkbox>
              </div>
              <div class="type" @click="query(item.id, item.name, item.level, item.type, item.content)">
                <div class="typeMiddle" style="display: flex; align-items: center">
                  <i :class="['iconfont', item.icon]" :style="{ color: getcolor(item.icon) }"></i>

                  <span
                    style="max-width: 100%; overflow: hidden; white-space: nowrap; display: flex; align-items: center; margin-left: 5%; text-overflow: ellipsis; height: 100%"
                    v-if="matchType(item.name) != 'image'"
                  >
                    <p style="white-space: nowrap; max-width: 100%; overflow: hidden; text-overflow: ellipsis">
                      {{ item.name }}
                    </p>
                  </span>
                  <span
                    v-else
                    :style="{
                      width: '100%',
                      height: '80%',
                      background: `url('${item.content}?imageView2/2/h/136')   left center/contain no-repeat`,
                    }"
                  ></span>
                </div>
                <div class="typeRight" style="width: 30%; height: 100%; display: flex; align-items: center; flex-direction: column; justify-content: center; align-items: flex-end">
                  <p class="typeDate" style="text-align: right; overflow: visible; max-width: 100%">
                    {{ timestampToTime(item.updateTime) }}
                  </p>
                  <div
                    style="width: 100%"
                    v-html="
                      matchType(item.name) == 'image'
                        ? `<p class=&quot;typeDate&quot;
                   style=&quot;text-align:right;max-width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis&quot;>
                  ${item.name}
                </p>`
                        : ''
                    "
                  ></div>
                </div>
              </div>
            </li>
          </template>
          <!-- 循环列表模式文件夹 -->
          <template v-else>
            <li
              v-for="(item, index) in collection"
              :key="index"
              v-show="
                moveList.findIndex((itemr) => {
                  return itemr.id === item.id
                }) === -1
              "
            >
              <div class="file" @click="change(item, index)">
                <checkbox v-model="item.flag"></checkbox>
              </div>
              <div class="type" @click="query(item.id, item.name, item.level, item.type, item.content)">
                <i :class="['iconfont', item.icon]" :style="{ color: getcolor(item.icon) }"></i>
                <span>{{ item.name }}</span>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <div class="upload" v-if="reciveList.length > 0" :style="{ bottom: recive ? '' : '-4.7rem' }">
        <div class="icon">
          <i class="iconfont icon-child-on" :style="{ transform: recive ? '' : 'rotate(-180deg)' }" @click="recive = !recive"></i>
        </div>
        <div class="filearea">
          <div class="item" v-for="(item, index) in reciveList" v-show="Object.keys(item).length > 0" :key="index">
            <div class="img">
              <i class="iconfont icon-txt" v-if="true"></i>
            </div>
            <div class="info">
              <div class="filename">{{ item.filename }}</div>
              <div style="display: flex">
                <div class="time">{{ item.time }}</div>
                <div @click="deleUpload(index)">
                  <i class="iconfont icon-shanchu1" style="color: #ccc"></i>
                </div>
              </div>
            </div>
            <div class="jindu">
              <div class="control" :style="{ width: item.wdt }"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="Content" v-if="watchDetail">
        <precentDetail :id="$route.query.docid" @changeflag="watchDetail = false" />
      </div>
      <div class="btns_area" v-if="isMove">
        <div class="cancel" @click="QX">取消</div>
        <div class="Yes" @click="pushHere">
          {{ iscopy && !iscollect ? '复制至此' : isMove && !iscollect ? '移动至此' : '收藏至此' }}
        </div>
      </div>
    </div>
    <div class="link" v-if="getlist === 2">
      <div class="title">
        <div>
          <i class="iconfont icon-back" @click="backsky"></i>
          <span>新建链接</span>
        </div>
      </div>
      <ul>
        <li>
          <div>
            <input type="text" placeholder="标题" v-model="link_title" />
          </div>
        </li>
        <li>
          <div>
            <input type="text" placeholder="请输入收藏地址" v-model="link_collect" />
          </div>
        </li>
      </ul>
      <div class="btn">
        <div
          @click="
            getlist = 1
            added = false
          "
        >
          取消
        </div>
        <div @click="link_save">保存</div>
      </div>
    </div>
    <div v-if="getlist === 3" class="preview">
      <preview @clearpl="clearpl" :id="numid" :userId="$store.state.user.userInfo.id"></preview>
    </div>
    <div v-if="getlist === 4" class="datail">
      <law-detail :id="lawid" :catlog="catlog" @changeflag="getlist = 1"></law-detail>
    </div>
    <div v-if="getlist === 5" class="datail">
      <precedentDetail :id="docid" @changeflag="getlist = 1" />
    </div>
    <preview-report :reportId="reportId" :visible="reportVisible" @back="closeReportPreview" />
    <van-popup position="top" v-model="shareDialogVisible">
      <div style="padding: 10px 20px">
        <div>
          <div>
            <span>分享文件：</span>
            <span v-for="item in selectItems" :key="item.id">
              <span>{{ item.name }}</span>
              <span>，</span>
            </span>
            <span>共({{ selectItems.length }})项</span>
          </div>
          <div style="display: flex; align-items: center; margin-top: 20px">
            <span>读取密码：</span>
            <van-field v-model="sharePassword" style="width: 100px" maxlength="4" />
            <!-- <el-input
            v-model="sharePassword"
            :clearable="true"
            maxlength="4"
            style="width:100px"
          ></el-input> -->
          </div>
          <div>
            <span style="color: #999999; font-size: 12px">可自定义读取密码，更加隐私安全。</span>
          </div>

          <div style="display: flex; align-items: center; margin-top: 20px">
            <span>有效期：</span>
            <van-radio-group v-model="effectiveTime" direction="horizontal">
              <van-radio :name="item.value" :key="item.value" v-for="item in effectiveTimeOptions">{{ item.label }}</van-radio>
            </van-radio-group>
          </div>
          <div style="display: flex; align-items: center; justify-content: center; margin-top: 20px">
            <van-button v-show="!shareUrl" type="info" @click="getShareUrl">生成分享链接</van-button>
            <van-button v-show="shareUrl" type="info" disabled>链接已生成，请粘贴</van-button>
          </div>
        </div>
        <div v-show="shareUrl">
          <div style="color: #ff6633; margin-top: 20px">链接已生成并复制到粘贴板，请直接粘贴到QQ、微信、钉钉。</div>
          <div style="margin-top: 10px; font-size: 12px; color: #999">
            {{ shareUrl }}，读取密码：{{ sharePassword }}
            <span style="margin-left: 20px; color: #3399ff; cursor: pointer" @click="copyShareUrl">复制</span>
          </div>
          <!-- <div style="margin-top:10px"></div> -->
          <!-- <div
          style="display:flex;align-items: center;justify-content: center;margin-top:20px"
        >
          <el-button type="info" disabled>链接已生成，请粘贴</el-button>
        </div> -->
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import checkbox from './compoments/checkbox'
// import disabledCheckbox from './compoments/disabledCheckbox'
import precentDetail from '@/pages/precedentDetail/index'
import { caseBase, qiniuBase, winBase } from '~api-config'
import { gettype } from './compoments/getType'
import preview from '@/pages/preview/index'
import previewReport from '@components/preview-report'
import Qs from 'qs'

export default {
  name: 'sky-drive',
  props: ['listid', 'listname', 'Type', 'modulename', 'summary', 'report', 'skydriveif'],
  components: {
    checkbox,
    precentDetail,
    preview,
    previewReport,
    precedentDetail: () => import('@/pages/precedentDetail/index'),
    lawDetail: () => import('@/pages/Search/pages/lawDetail/index'),
  },
  data() {
    this.t = null
    return {
      isShow: true,
      clientHeight: 0,
      daochuBtn: false,
      convertBtnPDF: false, // 转换成PDF
      convertBtnWORD: false, // 转换成WORD
      downloadZIPBtn: true, // 打包下载
      downloadBtn: true, // 下载按钮
      recycleBtn: true, // 移动到回收站按钮
      copyBtn: true, // 复制按钮
      deleteBtn: true, // 删除按钮
      moveBtn: true, // 移动按钮
      concatCaseBtn: true, // 关联案件按钮
      reportVisible: false,
      // 分析报告id
      reportId: null,
      // 当前文件夹的id
      currentParentId: null,
      // 当前文件夹
      currentParent: null,
      loading: false,
      photoModle: false, // 是否显示图片模式
      catlog: '',
      lawid: 0,
      docid: 0,
      numid: 0,
      link_collect: '',
      link_title: '',
      clear: false,
      getlist: 1,
      index: 0, // 光标处
      isMove: false, // 判断是否是移动
      moveList: [], // 移动的文件
      checkbox: false,
      file: false,
      screen: false, // 筛选网盘数据类型
      added: false, // 新建文件夹
      operation: false, // 增删改查的checkbox
      selectItems: [], // 选择的项集合
      tpe: false, // 增删改查
      collection: [],
      srh: false, // 新建文件夹搜索框
      value: '新建文件夹',
      MiMitype: '',
      recive: false,
      reciveList: [],
      type: '',
      dirs: [
        {
          name: '首页',
          id: 0,
          level: 0,
        },
      ],
      cancel: null,
      mimitype: '',
      isSearch: false,
      iscopy: false, // 是否复制
      page: 0,
      ssinput: '',
      iscollect: false,
      watchDetail: false,
      shoucang: '',
      preventUpdate: false,
      source: null,
      canScroll: true,
      painan: true,
      hatin: true,
      num: 0, // 预览图片
      // 分享弹框
      shareDialogVisible: false,
      // 分享密码
      sharePassword: '',
      // 分享有效期选择器
      effectiveTimeOptions: [
        {
          label: '1天',
          value: 1,
        },
        {
          label: '7天',
          value: 7,
        },
        {
          label: '永久',
          value: 0,
        },
      ],
      // 分享有效期
      effectiveTime: 7,
      // 分享链接
      shareUrl: '',
    }
  },
  methods: {
    showShouCangToast() {
      this.$toast('已收藏到网盘，请到电脑端查看')
    },
    // 分享
    share() {
      this.shareUrl = ''
      this.sharePassword = this.randomStr()
      this.shareDialogVisible = true
    },
    // 点击生成分享链接
    getShareUrl() {
      if (this.sharePassword.length !== 4) {
        return this.$notify('读取密码必须4位数字或字母')
      }
      const sourceIdList = this.selectItems.map((item) => {
        return item.id
      })
      const data = {
        sourceIdList: sourceIdList,
        password: this.sharePassword,
        effectiveTime: this.effectiveTime,
      }
      this.$axios.post(`${this.$base}/management/sourceShare/add.do`, data).then((res) => {
        if (res.data.code === 200) {
          this.$notify({
            message: '生成分享链接成功',
            type: 'success',
          })
          this.shareUrl = 'https://tool.farbun.com' + '/shareFiles' + res.data.data.url + '&name=' + this.userInfo.nickName
          const text = `链接：${this.shareUrl}\n读取密码：${this.sharePassword}`
          const textArea = document.createElement('textarea')
          textArea.value = text
          document.body.appendChild(textArea)
          textArea.select()
          document.execCommand('copy')
          textArea.remove()
        }
      })
    },
    // 复制分享的URL
    copyShareUrl() {
      const text = `链接：${this.shareUrl}\n读取密码：${this.sharePassword}`
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      textArea.remove()
      this.$notify({
        message: '分享地址已复制到剪贴板',
        type: 'success',
      })
    },
    // 封装一个随机生成数字的函数
    random(a, b) {
      var n = Math.round(Math.random() * (a - b) + b)
      return n
    },
    // 封装一个随机生成数的库
    randomStr() {
      // 定义一个字符串把所有可能都装进去，全部字母以及数字
      var codeStr = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
      // 声明一个空的字符串，用作装随机生成的四个数。
      var str = ''
      // 写一个循环用来重复随机生成几个数，以及将东西一个一个放进上面声明的空字符串内
      for (var i = 0; i < 4; i++) {
        var m = this.random(0, 61)
        str += codeStr.charAt(m)
      }
      // 在外面调用函数时，一定要在函数内写返回值
      return str
    },
    // 截取网盘的字数
    formDataName(name) {
      if (name.length > 20) {
        return name.substring(0, 20)
      } else {
        return name
      }
    },
    // 判断上传的文件是否是图片
    matchType(fileName) {
      // 后缀获取
      var suffix = ''
      // 获取类型结果
      var result = ''
      try {
        var flieArr = fileName.split('.')
        suffix = flieArr[flieArr.length - 1]
      } catch (err) {
        suffix = ''
      }
      // fileName无后缀返回 false
      if (!suffix) {
        result = false
        return result
      }
      // 图片格式
      var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      // 进行图片匹配
      result = imglist.some(function (item) {
        return item === suffix
      })
      if (result) {
        result = 'image'
        return result
      }
      // 匹配txt
      var txtlist = ['txt']
      result = txtlist.some(function (item) {
        return item === suffix
      })
      if (result) {
        result = 'txt'
        return result
      }
      // 匹配 excel
      var excelist = ['xls', 'xlsx']
      result = excelist.some(function (item) {
        return item === suffix
      })
      if (result) {
        result = 'excel'
        return result
      }
      // 匹配 word
      var wordlist = ['doc', 'docx']
      result = wordlist.some(function (item) {
        return item === suffix
      })
      if (result) {
        result = 'word'
        return result
      }
      // 匹配 pdf
      var pdflist = ['pdf']
      result = pdflist.some(function (item) {
        return item === suffix
      })
      if (result) {
        result = 'pdf'
        return result
      }
      // 匹配 ppt
      var pptlist = ['ppt']
      result = pptlist.some(function (item) {
        return item === suffix
      })
      if (result) {
        result = 'ppt'
        return result
      }
      // 匹配 视频
      var videolist = ['mp4', 'm2v', 'mkv']
      result = videolist.some(function (item) {
        return item === suffix
      })
      if (result) {
        result = 'video'
        return result
      }
      // 匹配 音频
      var radiolist = ['mp3', 'wav', 'wmv']
      result = radiolist.some(function (item) {
        return item === suffix
      })
      if (result) {
        result = 'radio'
        return result
      }
      // 其他 文件类型
      result = 'other'
      return result
    },
    // 格式化时间戳
    timestampToTime(timestamp) {
      var date = new Date(timestamp) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '/'
      var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/'
      var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return Y + M + D
    },
    computedWdt(ref) {
      let width = 0
      Array.from(ref.children).forEach((item) => {
        width += item.offsetWidth + 5
      })
      return width
    },
    getcolor(classname) {
      switch (classname) {
        case 'icon-wenjian3':
          return '#4b9efb'
        case 'icon-txt':
          return '#efa73f'
        case 'icon-html':
          return '#49c1ea'
        case 'icon-word':
          return '#6ca8ff'
        case 'icon-exl':
          return '#8bdd6c'
        case 'icon-ppt':
          return '#f3c143'
        case 'icon-PDF':
          return '#3eb3f0'
        case 'icon-jpg':
          return '#ff7739'
        case 'icon-shipin':
          return '#4b9efb'
        case 'icon-link':
          return '#4b9efb'
        case 'icon-website':
          return '#4b9efb'
        case 'icon-jinriricheng':
          return '#4b9efb'
        case 'icon-web':
          return '#4b9efb'
        case 'icon-falv':
          return '#4b9efb'
      }
    },
    clearpl() {
      this.getlist = 1
    },
    link_save() {
      this.added = false
      if (this.link_title.trim().length === 0) {
        this.$toast('请输入标题')
        return false
      } else if (this.link_collect.trim().length === 0) {
        this.$toast('请输入收藏地址')
        return false
      }
      this.$axios
        .post(`${caseBase}/management/user/${this.userId2}/collection`, {
          content: this.link_collect,
          level: this.dirs.length - 1,
          name: this.link_title,
          parentId: this.dirs[this.dirs.length - 1].id,
          type: 'WEBSITE',
          createTime: new Date().getTime(),
        })
        .then((res) => {
          if (res.data.code === 200) {
            this.$toast('新建链接成功')
            this.updatelist('added')
            this.getlist = 1
          } else {
            this.$toast('新建链接失败')
          }
        })
    },
    cancle() {},
    backsky() {
      this.getlist = 1
      this.added = false
    },
    cleardata() {
      this.ssinput = ''
    },
    // 获取图片
    getpicture(id) {
      let num = 0
      const arr = {
        index: -1,
        data: [],
      }
      const req = () => {
        this.$axios
          .get(
            `${caseBase}/management/user/${this.userId2}/collection?type=IMAGE&parentId=${this.dirs[this.dirs.length - 1].id}&level=${this.dirs.length - 1}&page=${num}&isShow=${this.isShow ? 0 : 1}`
          )
          .then((res) => {
            if (res.data.data.content.length === 0) {
              // 查询所预览图片是当前数组第几个
              arr.index = arr.data.findIndex((item) => {
                return item.previewFileId === id
              })

              const ua = navigator.userAgent
              if (ua === this.$ua.a) {
                // eslint-disable-next-line no-undef
                AND2JS.showPreviewPhotos(JSON.stringify(arr))
                return false
              } else if (ua === 'fb-flutter-Webview') {
                // eslint-disable-next-line??????
                window.showPreviewPhotos.postMessage(JSON.stringify({ params: arr }))
              } else if (ua === this.$ua.i) {
                // eslint-disable-next-line no-undef
                window.webkit.messageHandlers.showPreviewPhotos.postMessage(JSON.stringify(arr))
              } else {
                this.$notify('web端暂不支持该功能')
              }
            } else {
              res.data.data.content.forEach((item, index) => {
                const obj = {}
                obj.previewFileUrl = item.content
                obj.previewFileName = item.name
                obj.previewFileId = item.id
                arr.data.push(obj)
              })
              num++
              req(num)
            }
          })
      }
      req(num)
    },
    deleUpload(index) {
      this.$dialog
        .confirm({
          message: '是否要删除该文件？',
        })
        .then(() => {
          this.reciveList[index].$.preventUpload()
          this.reciveList[index] = {}
          if (
            this.reciveList.findIndex((item) => {
              return Object.keys(item) > 0
            }) === -1
          ) {
            this.painan = false
            this.updatelist('added')
            this.reciveList = []
          }
        })
        .catch((err) => {
          Promise.reject(err)
        })
    },
    bootcanp() {
      if (this.operation) {
        setTimeout(() => {
          this.cancel = false
        })
      }
    },
    // fenye () {
    // if (this.isSearch) {
    //   this.page = 0
    //   clearTimeout(this.t)
    //   this.t = setTimeout(() => {
    //     if (this.ssinput.trim().length === 0) { return false }
    //     this.collection = []
    //     this.page = 0
    //     this.getList('next', {
    //       parentId: this.dirs[this.dirs.length - 1].id,
    //       level: this.dirs.length - 1,
    //       type: this.mimitype,
    //       name: this.ssinput
    //     })
    //   }, 300)
    //   this.srh = false
    //   this.isSearch = false
    // }
    // },
    blur() {
      document.body.onclick = (e) => {
        var sinput = document.querySelector('.search')
        if (sinput) {
          if (!sinput.contains(e.target)) {
            this.srh = false
            this.isSearch = false
            this.added = false
          }
        }
        document.body.onclick = null
      }
    },
    load(e) {
      console.log(e.target.scrollTop)
      console.log(e.target.scrollTop, e.target.offsetHeight, e.target.scrollHeight)
      if ((e.target.scrollTop + e.target.offsetHeight + 5).toFixed() >= e.target.scrollHeight && this.canScroll && this.hatin) {
        this.page++
        if (this.ssinput.trim()) {
          this.handleSearch({
            parentId: this.dirs[this.dirs.length - 1].id,
            level: this.dirs.length - 1,
            type: this.mimitype,
            name: this.ssinput.trim(),
          })
        } else {
          this.getList('next', {
            type: this.mimitype,
            parentId: this.dirs[this.dirs.length - 1].id,
            level: this.dirs.length - 1,
          })
        }
      }
    },
    updatelist(type, newname) {
      if (type === 'added') {
        try {
          ;((page, top) => {
            this.canScroll = false
            this.collection = []
            this.picture = []
            this.source = this.$axios.CancelToken.source()
            if (this.source) {
              this.source.cancel()
            }
            const req = () => {
              this.$axios
                .get(
                  `${caseBase}/management/user/${this.userId2}/collection?type=${this.mimitype}&parentId=${this.dirs[this.dirs.length - 1].id}&level=${this.dirs.length - 1}&page=0&size=${
                    (page + 1) * 20
                  }&isShow=${this.isShow ? 0 : 1}`,
                  {},
                  {
                    cancelToken: this.source.token,
                  }
                )
                .then((res) => {
                  this.concatData(res)
                  setTimeout(() => {
                    this.$refs.scroller.scrollTop = top
                    setTimeout(() => {
                      this.canScroll = true
                      this.painan = true
                    }, 300)
                  }, 300)
                })
                .catch((err) => {
                  Promise.reject(err)
                })
            }
            req()
          })(this.page, this.$refs.scroller ? this.$refs.scroller.scrollTop : 0)
        } catch {}
        return
      }
      this.selectItems.forEach((item, index) => {
        const num = this.collection.findIndex((itemr) => {
          return itemr.id === item.id
        })
        switch (type) {
          case 'remove':
            this.collection.splice(num, 1)
            break
          case 'new_name':
            this.$set(this.collection[num], 'name', newname)
            break
        }
        if (index === this.selectItems.length - 1) {
          this.operation = false
          if (this.collection.length === 0) {
            this.$axios
              .get(
                `${caseBase}/management/user/${this.userId2}/collection?type=${this.mimitype}&parentId=${this.dirs[this.dirs.length - 1].id}&level=${this.dirs.length - 1}&page=${this.page}&isShow=${
                  this.isShow ? 0 : 1
                }`
              )
              .then((res) => {
                this.concatData(res)
              })
          }
        }
      })
    },
    Complete() {
      this.added = false
      // 新建文件夹点击确定
      if (!this.isSearch) {
        if (this.value.trim().length === 0) {
          this.$toast('不能新建空的文件夹')
          return false
        }
        this.isShow = false
        this.collection = []
        this.page = 0
        this.getList('newItem', {
          content: '',
          level: this.dirs.length === 1 ? 0 : this.dirs.length - 1,
          name: this.value,
          parentId: this.dirs[this.dirs.length - 1].id,
          size: 0,
          type: 'FOLDER',
        })
        this.srh = false
        setTimeout(() => {
          this.hatin = true
          this.canScroll = true
        }, 100)
      }
    },
    QX() {
      if (this.Type === 'REPORT') {
        return this.$emit('CancelCollect')
      } else if (this.Type === 'SEARCHREPORT' && this.listname) {
        return this.$emit('CancelCollect')
      } else if (this.listid && this.listname) {
        this.$emit('CancelCollect', false)
        return false
      }
      this.isMove = false
      this.iscopy = false
      this.mimitype = ''
      this.selectItems = this.moveList.slice()
      this.moveList = []
      this.tpe = true
    },
    quxiao() {
      this.iscopy = false
      this.isSearch = false
      this.srh = false
      this.added = false
      // this.value = ''
    },
    canqu() {
      this.srh = false
      this.isSearch = false
    },
    SouSuo() {
      this.ssinput = ''
      this.isSearch = true
      this.srh = true
      setTimeout(() => {
        this.$refs.ssInt.focus()
      }, 100)
    },
    copy() {
      this.selectItems.forEach((item, index) => {
        if (item.type === 'FOLDER') {
          this.$toast('目前该不支持文件夹复制')
        } else {
          this.iscopy = true
          this.isMove = true
          this.tpe = false
          this.mimitype = null
          setTimeout(() => {
            this.mimitype = ''
          })
          this.moveList = this.selectItems.slice()
          this.collection = []
        }
      })
    },
    pushHere() {
      // console.log(this.Type, this.currentParent, 'pushHere  pushHere')
      if (this.Type === 'REPORT') {
        return this.$emit('getId', this.currentParentId)
      } else if (this.selectItems.length > 0) {
        if (this.selectItems[0].type !== 'FOLDER') {
          this.$toast('必须为文件夹')
          return false
        }
      } else if (this.Type === 'SEARCHREPORT') {
        return this.$emit('getFold', this.currentParent)
      }
      // 移动测试
      let id, level
      if (this.selectItems.length > 0) {
        id = this.selectItems[0].id
        level = this.selectItems[0].level + 1
      } else {
        id = this.dirs[this.dirs.length - 1].id
        level = this.dirs.length - 1
      }
      if (this.iscollect) {
        // 收藏
        // console.log('收藏')
        this.collection = []
        if (typeof this.listid === 'string') {
          // console.log('收藏if')
          if (this.report) {
            // console.log('收藏if this.report')
            const params = { route: '/ana/create' }
            params.params = `id=${this.listid}&is_create=0`
            this.$axios
              .post(`${caseBase}/management/user/${this.userId2}/collection`, {
                content: JSON.stringify(params),
                level: level,
                name: this.listname,
                parentId: id,
                size: 0,
                type: 'LINK',
              })
              .then((res) => {
                this.$emit('CancelCollect', true)
                this.$emit('getId', res.data.data.id)
              })
          } else {
            // console.log('收藏else this.report')
            const params = { route: '/search' }
            params.params = `${this.Type}=${this.listid}`
            this.$axios
              .post(`${caseBase}/management/user/${this.userId2}/collection`, {
                content: JSON.stringify(params),
                level: level,
                name: this.listname,
                parentId: id,
                size: 0,
                type: 'LINK',
              })
              .then((res) => {
                this.$emit('getId', res.data.data.id)
                this.$emit('CancelCollect', true)
              })
          }
        } else {
          // console.log('收藏else')
          const date = new Date()
          this.$axios
            .post(`${caseBase}/management/user/${this.userId2}/collection`, {
              content: JSON.stringify(this.listid),
              level: level,
              name: `${this.summary || ''} ${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()} 《${this.modulename}》`,
              parentId: id,
              size: 0,
              type: this.Type,
            })
            .then((res) => {
              this.$emit('getId', res.data.data.id)
              this.$emit('CancelCollect', true)
            })
        }
        return false
      }
      if (this.iscopy) {
        this.mimitype = ''
        this.getList(
          'save',
          {
            content: `${this.moveList[0].content}`,
            level: level,
            name: this.moveList[0].name,
            size: this.moveList[0].size,
            parentId: id,
            type: this.moveList[0].type,
          },
          () => {
            this.collection = []
            this.mimitype = ''
            this.iscopy = false
            this.isMove = false
            this.$notify({
              message: '复制成功',
              duration: 1000,
              background: '#1989fa',
            })
            this.page = 0
            this.moveList = []
            this.getList('next', {
              type: this.mimitype,
              parentId: this.dirs[this.dirs.length - 1].id,
              level: this.dirs.length - 1,
            })
            return true
          }
        )
      } else {
        this.collection = []
        const moveList = this.moveList.map((item) => {
          var obj = {
            id: item.id,
            parentId: id,
          }
          return obj
        })
        this.$axios.put(`${caseBase}/management/user/${this.userId2}/collection`, moveList).then((res) => {
          this.isMove = false
          this.$notify({
            message: '移动成功',
            duration: 1000,
            background: '#1989fa',
          })
          this.moveList = []
          this.mimitype = null
          setTimeout(() => {
            this.mimitype = ''
          }, 100)
          this.page = 0
        })
      }
    },
    back() {
      if (this.skydriveif) {
        // 关闭
        this.$emit('close')
        return
      }
      if (this.listid && this.listname) {
        this.$emit('CancelCollect', false)
      } else if (this.isMove) {
        this.isMove = false
        this.mimitype = ''
        this.moveList = []
        this.iscopy = false
      } else {
        const ua = navigator.userAgent
        // eslint-disable-next-line no-undef
        if (ua === this.$ua.a) {
          try {
            // eslint-disable-next-line
            AND2JS.back()
          } catch {
            this.$notify('没有可以返回的页面')
          }
          // eslint-disable-next-line no-undef
        } else if (ua === this.$ua.i) {
          try {
            // eslint-disable-next-line
            window.webkit.messageHandlers.back.postMessage({})
          } catch {
            this.$notify('没有可以返回的页面')
          }
        } else if (ua === 'fb-flutter-Webview') {
          try {
            // eslint-disable-next-line
            window.back.postMessage('{}')
          } catch (err) {
            this.$notify('没有可以返回的页面')
            console.log('back.postMessage', err)
          }
        }
      }
    },

    moveadd() {
      this.value = '新建文件夹'
      this.srh = true
      setTimeout(() => {
        this.$refs.sousuo.focus()
        this.$refs.sousuo.select()
      }, 300)
    },
    recycle() {
      if (this.selectItems.length > 1) {
        this.$toast('目前只支持单个文件夹移动到回收站')
        return false
      }
      if (!this.selectItems[0].isDeletable) {
        this.$toast('该文件文件夹禁止移动到回收站')
        return false
      }
      this.$dialog
        .confirm({
          message: '是否移动到回收站?',
        })
        .then(() => {
          this.$axios
            .put(`${caseBase}/management/user/${this.userId2}/collection/${this.selectItems[0].id}`, {
              level: 0,
              parentId: -1,
            })
            .then((res) => {
              this.$notify({
                message: '移动到回收站成功',
                duration: 1000,
                background: '#1989fa',
              })
              this.updatelist('remove')
            })
        })
        .catch(() => {})
    },
    // 下载分析报告
    dowloadReport(content) {
      console.log(content)
      const section = content.section
      // 饼状图
      const divorceGraph = {
        num: section.semantic.num,
        title: section.semantic.title,
      }
      // 争议焦点集合
      const divorceControversials = []
      section.focus.map((item) => {
        var obj = {
          // doc_id: item.doc_id,
          text: item.name,
          case_name: item.case_name,
          case_id: item.case_id,
          court_name: item.court_name,
          pub_date: item.pub_date,
        }
        divorceControversials.push(obj)
      })
      // 判例使用的法律法规
      const divorceEntities = []
      section.judgment.map((item) => {
        var obj = {}
        obj.name = item.name
        obj.divorceCataloguess = []
        item.children.map((childItem) => {
          var itemObj = {
            text: childItem.content,
            title: childItem.name,
          }
          obj.divorceCataloguess.push(itemObj)
        })
        divorceEntities.push(obj)
      })
      // 判例使用的法律法规
      const divorceSimilarity = []
      section.appeal.map((item) => {
        var obj = {}
        obj.case_id = item.case_id
        obj.case_name = item.case_name
        obj.court_name = item.court_name
        obj.pub_date = item.pub_date
        obj.divorceCases = []
        item.children.map((childItem) => {
          var itemobj = {}
          itemobj.title = childItem.name
          itemobj.datas = childItem.content
          obj.divorceCases.push(itemobj)
        })
        divorceSimilarity.push(obj)
      })
      // 柱状图
      const divorceReportData = {
        moths: section.chart.month.title,
        mothsData: section.chart.month.data,
        years: section.chart.year.title,
        yearsData: section.chart.year.data,
      }
      let divorceDataMap = null
      if (!section.divorceDataMap.deleted) {
        divorceDataMap = {
          areaNum: {
            names: section.divorceDataMap.province.title,
            values: section.divorceDataMap.province.data,
          },
          proceeding: {
            names: section.divorceDataMap.proceeding.title,
            values: section.divorceDataMap.proceeding.data,
          },
          courtLever: {
            names: section.divorceDataMap.courtLevel.title,
            values: section.divorceDataMap.courtLevel.data,
          },
          trialCourt: {
            names: section.divorceDataMap.courtName.title,
            values: section.divorceDataMap.courtName.data,
          },
          trialYears: {
            names: section.divorceDataMap.judgeYear.title,
            values: section.divorceDataMap.judgeYear.data,
          },
        }
      }
      const parmas = {
        divorceGraph: divorceGraph,
        divorceReportData: divorceReportData,
        divorceControversials: divorceControversials,
        divorceEntities: divorceEntities,
        divorceSimilarity: divorceSimilarity,
        nickName: content.user.nickName,
        sentence: content.basic || '离婚',
        summary: section.semantic.name || '离婚纠纷',
        lawyerSuggestion: content.lawyerSuggestion,
        divorceDataMap: divorceDataMap,
      }
      this.$axios({
        method: 'POST',
        url: `${this.$base}/document/convertoffice/DowAnalysis2`,
        data: parmas,
        dataType: 'JSONP',
        // responseType: 'blob'
      })
        .then((res) => {
          console.log(res, '====>>> 接口返参14')
          if (res.data.code === '200' && res.data.data) {
            // window.location.href = res.data.data
            const ua = navigator.userAgent
            if (ua === this.$ua.a) {
              // eslint-disable-next-line
              AND2JS.showFilePreview(res.data.data)
            } else if (ua === 'fb-flutter-Webview') {
              // eslint-disable-next-line
              window.showFilePreview.postMessage(
                JSON.stringify({
                  filePath: res.data.data.filePath,
                  fileName: res.data.data.fileName,
                })
              )
            } else if (ua === this.$ua.i) {
              // ios环境
              // eslint-disable-next-line
              window.webkit.messageHandlers.fbDownloadFile.postMessage(res.data.data)
            } else {
              console.log('非原生端', res.data.data)
            }
          } else {
            this.$notify('下载失败')
          }
        })
        .catch((err) => {
          this.$notify('下载失败')
          console.log(err)
        })
    },

    // PDF WORD转换
    convert(type) {
      this.$dialog
        .confirm({
          message: '是否下载该文件?',
        })
        .then(() => {
          if (type === 'PDF') {
            this.$axios
              .get(`${this.$base}/management/custom/download?fileId=${this.selectItems[0].id}&type=${type}`)
              .then((res) => {
                if (res.data.code === 200) {
                  const url = `https:${res.data.data}`
                  const name = url.substring(url.lastIndexOf('/') + 1)
                  const pathUrl = url.substring(0, url.lastIndexOf('/'))
                  // window.location.href = res.data.data
                  const ua = navigator.userAgent
                  if (ua === this.$ua.a) {
                    // eslint-disable-next-line
                    AND2JS.showFilePreview(res.data.data)
                  } else if (ua === 'fb-flutter-Webview') {
                    // eslint-disable-next-line
                    window.showFilePreview.postMessage(
                      JSON.stringify({
                        filePath: pathUrl,
                        fileName: name,
                      })
                    )
                  } else if (ua === this.$ua.i) {
                    // ios环境
                    // eslint-disable-next-line
                    window.webkit.messageHandlers.fbDownloadFile.postMessage(res.data.data)
                  } else {
                    console.log('非原生端', res.data.data)
                  }
                }
              })
              .catch((err) => {
                console.log(err)
                this.$notify('下载失败')
              })
          } else if (type === 'WORD') {
            let url = this.selectItems[0].content
            if (url !== 'http') {
              url = 'http:' + url
            }
            const params = {
              url: url,
            }
            this.$axios
              .post(`${winBase}/file/convert`, Qs.stringify(params), {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              })
              .then((res) => {
                console.log(res, '====>>> 接口返参16', '接口可能失效')
                if (res.data.code === 200) {
                  const wordUrl = 'http:' + qiniuBase + '/' + res.data.data.url
                  // window.location.href = res.data.data
                  const ua = navigator.userAgent
                  if (ua === this.$ua.a) {
                    // eslint-disable-next-line
                    AND2JS.showFilePreview(wordUrl)
                  } else if (ua === 'fb-flutter-Webview') {
                    // eslint-disable-next-line??
                    window.showFilePreview.postMessage(
                      JSON.stringify({
                        filePath: wordUrl,
                        // fileName: res.data.data.fileName,
                      })
                    )
                  } else if (ua === this.$ua.i) {
                    // ios环境
                    // eslint-disable-next-line
                    window.webkit.messageHandlers.fbDownloadFile.postMessage(wordUrl)
                  } else {
                    const url = wordUrl
                    const link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', name)
                    document.body.appendChild(link)
                    link.click()
                    window.URL.revokeObjectURL(link.href)
                    this.$notify({ type: 'success', message: '下载成功' })
                  }
                } else {
                  this.$notify.error('下载失败')
                }
              })
              .catch(() => {
                this.$notify.error('下载失败')
              })
          }
        })
    },
    // 导出PDF或者word
    daochu(type) {
      this.$dialog
        .confirm({
          message: '是否导出该文件?',
        })
        .then(() => {
          const urls = []
          this.selectItems.forEach((item) => {
            urls.push(item.content)
          })
          this.$axios
            .post(`${this.$base}/document/convertoffice/composePicturesToWord`, {
              kind: type,
              urls: urls,
            })
            .then((res) => {
              if (res.data.code === '200') {
                const url = res.data.data.path
                const name = url.substring(url.lastIndexOf('/') + 1)
                const pathUrl = url.substring(0, url.lastIndexOf('/'))
                // window.location.href = res.data.data
                const ua = navigator.userAgent
                if (ua === this.$ua.a) {
                  // eslint-disable-next-line
                  AND2JS.showFilePreview(res.data.data.path)
                } else if (ua === 'fb-flutter-Webview') {
                  // eslint-disable-next-line
                  window.showFilePreview.postMessage(
                    JSON.stringify({
                      filePath: pathUrl,
                      fileName: name,
                    })
                  )
                } else if (ua === this.$ua.i) {
                  // ios环境
                  // eslint-disable-next-line
                  window.webkit.messageHandlers.fbDownloadFile.postMessage(res.data.data.path)
                } else {
                  console.log('非原生端', res.data.data.path)
                }
              }
            })
            .catch((err) => {
              console.log(err)
              this.$notify('下载失败')
            })
        })
    },
    // 下载压缩包
    downloadZIP() {
      this.$dialog
        .confirm({
          message: '是否下载该文件?',
        })
        .then(() => {
          const urls = []
          this.selectItems.map((item) => {
            let url = ''
            if (item.content.slice(0, 4) === 'http') {
              url = item.content
            } else {
              if (window.location.href.slice(0, 5) === 'https') {
                url = `https:${item.content}`
              } else {
                url = `http:${item.content}`
              }
            }
            urls.push(url)
          })
          const formData = new FormData()
          formData.append('urls', urls)
          const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
          this.$axios
            .post(`${this.$base}/document/convertoffice/dowFileZIP`, formData, config)
            .then((res) => {
              if (res.data.code === '200') {
                const url = res.data.data
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', name)
                document.body.appendChild(link)
                link.click()
                window.URL.revokeObjectURL(link.href)
                this.$notify({ type: 'success', message: '下载成功' })
              }
            })
            .catch((err) => {
              console.log(err)
              this.$notify('下载失败')
            })
        })
    },

    download() {
      this.selectItems.forEach((item, index) => {
        if (
          item.type === 'FOLDER' ||
          item.type === 'LINK' ||
          item.type === 'SEARCHREPORT' ||
          item.type === 'WEEKREPORT' ||
          item.type === 'UNKNOWN' ||
          item.type === 'LAW_FRAGMENT' ||
          item.type === 'LEGAL_PRECEDENT_FRAGMENT'
        ) {
          this.$toast('暂不支持文件夹下载')
        } else if (item.type === 'REPORT') {
          this.dowloadReport(JSON.parse(item.content))
        } else if (item.type === 'WORD' || item.type === 'EXCEL' || item.type === 'PPT' || item.type === 'PDF') {
          this.$dialog
            .confirm({
              message: '是否下载该文件?',
            })
            .then(() => {
              let url = ''
              if (item.content.slice(0, 4) === 'http') {
                url = item.content
              } else {
                if (window.location.href.slice(0, 5) === 'https') {
                  url = `https:${item.content}`
                } else {
                  url = `http:${item.content}`
                }
              }
              const name = url.substring(url.lastIndexOf('/') + 1)
              const pathUrl = url.substring(0, url.lastIndexOf('/'))

              // window.location.href = res.data.data
              const ua = navigator.userAgent
              if (ua === this.$ua.a) {
                // eslint-disable-next-line
                AND2JS.showFilePreview(url)
              } else if (ua === 'fb-flutter-Webview') {
                // eslint-disable-next-line
                window.showFilePreview.postMessage(
                  JSON.stringify({
                    filePath: pathUrl,
                    fileName: name,
                  })
                )
              } else if (ua === this.$ua.i) {
                // ios环境
                // eslint-disable-next-line
                console.log(window.webkit.messageHandlers.fbDownloadFile, url, '*****************>>>> ios下载fbDownloadFile对象')
                window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
              } else {
                console.log('非原生端', url)
              }
            })
            .catch((err) => {
              console.log(err, 'err*****************')
              Promise.reject(err)
              this.$notify('下载失败')
            })
        } else {
          this.$dialog
            .confirm({
              message: '是否下载该文件?',
            })
            .then(() => {
              let url = ''
              if (item.content.slice(0, 4) === 'http') {
                url = item.content
              } else {
                if (window.location.href.slice(0, 5) === 'https') {
                  url = `https:${item.content}`
                } else {
                  url = `http:${item.content}`
                }
              }
              fetch(url)
                .then((response) => response.blob())
                .then((data) => {
                  if (!data) return
                  const url = window.URL.createObjectURL(new Blob([data]))
                  const link = document.createElement('a')
                  link.style.display = 'none'
                  link.href = url
                  link.setAttribute('download', name)
                  document.body.appendChild(link)
                  link.click()
                  window.URL.revokeObjectURL(link.href)
                  this.$notify({ type: 'success', message: '下载成功' })
                })
            })
            .catch((err) => {
              Promise.reject(err)
            })
        }
      })
    },

    Delete() {
      this.$dialog
        .confirm({
          message: '是否删除该文件?',
        })
        .then(() => {
          const arr = []
          this.selectItems.forEach((item) => {
            if (item.isDeletable) {
              arr.push({
                id: item.id,
              })
            }
          })
          if (arr.length === 0) {
            this.$notify({
              message: '该文件不可删除',
              duration: 1000,
              background: '#1989fa',
            })
            return
          }
          this.$axios({
            url: `${caseBase}/management/user/${this.userId2}/collection/delete`,
            method: 'post',
            data: arr,
            header: {
              'Content-Type': 'application/json;charset=utf-8',
            },
          }).then((res) => {
            if (res.data.code === 200) {
              this.$notify({
                message: '删除成功',
                duration: 1000,
                background: '#1989fa',
              })
            }
            this.updatelist('remove')
          })
        })
        .catch(() => {})
    },
    goBack(id, level) {
      this.operation = false
      setTimeout(() => {
        this.hatin = true
        this.canScroll = true
      }, 100)
      this.page = 0
      this.collection = []
      this.selectItems = []
      this.getList('next', {
        type: this.mimitype,
        parentId: id,
        level: level,
      })
      this.dirs.splice(level + 1, this.dirs.length - level)
      if (this.dirs.length > 5) {
        this.index = level - 5
      }
    },
    change(item, index) {
      if (!item.flag) {
        this.operation = false
      } else if (this.isMove) {
        this.collection.forEach((item, num) => {
          if (index === num) {
            return
          }
          this.$set(this.collection[num], 'flag', false)
        })
      } else {
        const index = this.collection.findIndex((item) => {
          return !item.flag
        })
        if (index === -1) {
          this.operation = true
          this.cancel = false
        }
      }
    },
    query(id, name, level, type, url) {
      // console.log(id, name, level, type, url)
      this.page = 0
      console.log(url, '====>>> 接口返参19')

      if (type === 'REPORT') {
        this.openReportPreview(id)
      } else if (type === 'WEEKREPORT') {
        const url = `https://tool.farbun.com/weekReport/${id}`
        this.$toast(`周报访问网址为:${url}`)
      } else if (type === 'FOLDER') {
        this.dirs.push({
          name: name,
          id: id,
          level: level + 1,
        })
        setTimeout(() => {
          const rem = parseFloat(getComputedStyle(document.documentElement)['font-size'])
          this.$refs.fq.style.width = this.computedWdt(this.$refs.fq) / rem + 'rem'
          this.$refs.fq.parentElement.scrollLeft = this.$refs.fq.scrollWidth
        }, 100)
        this.collection = []
        this.getList('next', {
          type: this.mimitype,
          parentId: id,
          level: this.dirs.length === 1 ? 0 : this.dirs.length - 1,
        })
        if (this.dirs.length > 5) {
          this.index = this.dirs.length - 5
        }
        setTimeout(() => {
          this.hatin = true
          this.canScroll = true
        }, 100)
      } else if (type === 'IMAGE') {
        // eslint-disable-next-line no-undef
        this.getpicture(id)
      } else if (type === 'LAW_FRAGMENT' || type === 'LEGAL_PRECEDENT_FRAGMENT') {
        this.numid = id
        this.getlist = 3
      } else if (type === 'LINK') {
        console.log(url, 'url url url url')
        if (JSON.parse(url).params) {
          if (JSON.parse(url).params.indexOf('is_create') > 0) {
            const link = `${window.document.location.origin}/ana/create?${JSON.parse(url).params}`
            this.$toast(`数据网址为:${link}`)
          } else {
            const params = JSON.parse(url).params.split('&')
            let name = ''
            let id = ''
            params.forEach((item) => {
              if (item.split('=')[0] === 'docid' || item.split('=')[0] === 'lawid') {
                name = item.split('=')[0]
                id = item.split('=')[1]
              }
            })
            if (name === 'docid') {
              // 判例
              this.docid = id
              this.getlist = 5
            } else {
              // 法律
              this.lawid = id
              this.getlist = 4
            }
          }
        }
      } else if (type === 'SEARCHREPORT') {
        this.$router.push(`/transform/searchReport/${id}`)
      } else if (type === 'WEBSITE') {
        this.$toast(`数据网址为:${url}`)
      } else {
        const ua = navigator.userAgent
        if (ua === this.$ua.a) {
          // eslint-disable-next-line no-undef
          AND2JS.showFilePreviewWithFileName(url, name)
        } else if (ua === 'fb-flutter-Webview') {
          // eslint-disable-next-line
          window.showFilePreview.postMessage(
            JSON.stringify({
              filePath: url,
              fileName: name,
            })
          )
        } else if (ua === this.$ua.i) {
          // eslint-disable-next-line no-undef
          window.webkit.messageHandlers.showFilePreview.postMessage(url)
        }
      }
    },
    getList(type, params, fn) {
      this.loading = true
      switch (type) {
        case 'filterType': // 筛选类型
          this.$axios
            .get(`${caseBase}/management/user/${this.userId2}/collection?type=${params.type}&isShow=${this.isShow ? 0 : 1}`)
            .then((res) => {
              this.loading = false
              this.concatData(res)
            })
            .catch((err) => {
              this.loading = false
              console.log(err)
            })
          break
        case 'next': // 下一级目录
          this.$axios
            .get(
              `${caseBase}/management/user/${this.userId2}/collection?type=${params.type}&parentId=${params.parentId}&level=${params.level}&name=${params.name || ''}&page=${this.page}&isShow=${
                this.isShow ? 0 : 1
              }`
            )
            .then((res) => {
              // 将当前文件夹的id保存起来一边其他地方使用该数据
              this.currentParentId = params.parentId
              this.currentParent = params
              this.loading = false
              this.concatData(res)
              setTimeout(() => {
                this.preventUpdate = true
              })
            })
            .catch((err) => {
              this.loading = false
              Promise.reject(err)
            })
          break
        case 'save': // 保存到业务系统
          this.$axios
            .post(`${caseBase}/management/user/${this.userId2}/collection`, {
              content: `${params.content}`,
              level: params.level,
              name: params.name,
              size: params.size,
              parentId: params.parentId,
              type: params.type,
            })
            .then(() => {
              this.loading = false
              let flag = false
              if (fn) {
                flag = fn()
              }
              if (flag) {
                return
              }
              this.getList('next', {
                type: this.mimitype,
                parentId: this.dirs[this.dirs.length - 1].id,
                level: this.dirs.length === 1 ? 0 : this.dirs.length - 1,
              })
            })
            .catch((err) => {
              this.loading = false
              console.log(err)
            })
          break
        case 'newItem': // 新建文件夹
          this.$axios
            .post(`${caseBase}/management/user/${this.userId2}/collection`, {
              content: params.content,
              level: params.level,
              name: params.name,
              parentId: params.parentId,
              size: params.size,
              type: params.type,
            })
            .then(() => {
              this.loading = false
              this.getList('next', {
                type: this.mimitype,
                parentId: this.dirs[this.dirs.length - 1].id,
                level: this.dirs.length === 1 ? 0 : this.dirs.length - 1,
              })
            })
          break
      }
    },
    concatData(res) {
      if (!res.data.data) {
        return
      }
      // 用于接收
      res.data.data.content.map((item, index) => {
        item.flag = false
        switch (item.type) {
          case 'FOLDER':
            item.icon = 'icon-wenjian3'
            break
          case 'TXT':
            item.icon = 'icon-txt'
            break
          case 'HTML':
            item.icon = 'icon-html'
            break
          case 'WORD':
            item.icon = 'icon-word'
            break
          case 'EXCEL':
            item.icon = 'icon-exl'
            break
          case 'PPT':
            item.icon = 'icon-ppt'
            break
          case 'PDF':
            item.icon = 'icon-PDF'
            break
          case 'IMAGE':
            item.icon = 'icon-jpg'
            break
          case 'VIDEO':
            item.icon = 'icon-shipin'
            break
          case 'AUDIO':
            item.icon = 'icon-shipin'
            break
          case 'LINK':
            item.icon = 'icon-link'
            break
          case 'WEEKREPORT':
            item.icon = 'icon-link'
            break
          case 'SEARCHREPORT':
            item.icon = 'icon-link'
            break
          case 'WEBSITE':
            item.icon = 'icon-website'
            break
          case 'FRAGMENT':
            item.icon = 'icon-web'
            break
          case 'LAW_FRAGMENT':
            item.icon = 'icon-falv'
            break
          case 'LEGAL_PRECEDENT_FRAGMENT':
            item.icon = 'icon-panli'
            break
          case 'ARTICLE_FRAGMENT':
            item.icon = 'icon-web'
            break
          case 'TASK':
            item.icon = 'icon-jinriricheng'
            break
          case 'UNKNOWN':
            item.icon = 'icon-weizhi1'
            break
          case 'REPORT':
            item.icon = 'icon-link'
        }
        return item
      })
      if (res.data.data.content.length === 0) {
        this.hatin = false
        return false
      }
      this.collection = this.collection.concat(res.data.data.content)
    },

    // 失焦的时候关闭新建文件夹
    shijiao() {
      // 新建文件夹 此时为最外层的文件夹
      // this.added = false
    },
    upload() {
      // 新建文件夹
      this.value = '新建文件夹'
      this.srh = true
      setTimeout(() => {
        this.$refs.sousuo.focus()
        this.$refs.sousuo.select()
      }, 100)
    },
    add() {
      // 新增文件夹
      this.added = !this.added
      if (this.screen) {
        this.screen = false
      }
      const fn = (e) => {
        var haha = document.querySelector('.icon-p')
        if (haha) {
          if (!haha.contains(e.target)) {
            this.added = false
          }
        }
        document.body.removeEventListener('click', fn)
      }
      setTimeout(() => {
        document.body.addEventListener('click', fn)
      })
    },
    move() {
      // if (this.selectItems.length > 1) {
      //   this.$toast('目前只支持单个文件移动')
      //   return false
      // }
      if (!this.selectItems[0].isDeletable) {
        this.$toast('该文件夹禁止移动')
        return false
      }
      this.isMove = true
      this.moveList = this.selectItems.slice()
      this.selectItems = []
      this.tpe = false
      this.mimitype = ''
    },
    getType(file) {
      switch (file) {
        case 'text/plain':
          return 'TXT'
        case 'text/html':
          return 'HTML'
        case 'application/msword':
          return 'WORD'
        case 'application/vnd.ms-excel':
          return 'EXCEL'
        case 'application/vnd.ms-powerpoint':
          return 'PPT'
        case 'application/pdf':
          return 'PDF'
        default:
          if (file.indexOf('image/') > -1) {
            return 'IMAGE'
          } else if (file.indexOf('video/') > -1) {
            return 'VIDEO'
          } else if (file.indexOf('audio/') > -1) {
            return 'AUDIO'
          } else {
            return 'UNKNOWN'
          }
      }
    },
    uploadfile() {
      const ua = navigator.userAgent
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(0)
        return false
      } else if (ua === 'fb-flutter-Webview') {
        console.log('uploadPhoto...flutter')
        window.toAndrPickFile.postMessage('0')
        return false
      } else {
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.id = 'file' // 设置ID以便删除
        ipt.type = 'file'
        ipt.multiple = true // 开启支持多选
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          this.recive = true
          Array.from(ipt.files).forEach((item) => {
            const qiniu = new this.$qiniu()
            const index = this.reciveList.length
            const date = new Date()
            this.reciveList.push({
              filename: item.name,
              time: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}更新\t\t${(item.size / 1024).toFixed(2)}KB`,
              wdt: '0%',
              $: qiniu,
            })
            qiniu.UploadFile(
              'DEFAULT',
              item,
              {
                next: (file) => {
                  this.$set(this.reciveList[index], 'wdt', file.total.percent + '%')
                },
                complete: () => {
                  this.$set(this.reciveList[index], 'wdt', '100%')
                  this.reciveList[index] = {}
                  if (
                    this.reciveList.filter((item) => {
                      return Object.keys(item).length > 0
                    }).length === 0
                  ) {
                    this.reciveList = []
                  }
                },
                error: () => {
                  this.$toast('上传失败，上传终止')
                  this.reciveList[index] = {}
                },
              },
              (fileopt, key, file) => {
                console.log(fileopt)
                console.log(key)
                console.log(file)
                this.$axios
                  .post(`${caseBase}/management/user/${this.userId2}/collection`, {
                    content: `//oss.farbun.com/${key}`,
                    level: this.dirs.length - 1,
                    name: fileopt.name,
                    size: fileopt.size,
                    parentId: this.dirs[this.dirs.length - 1].id,
                    type: this.getType(gettype(fileopt.name)),
                  })
                  .then((res) => {
                    if (res.data.code === 404) {
                      this.$toast(res.data.message)
                    } else {
                      this.$toast('上传成功')
                      if (
                        this.reciveList.findIndex((item) => {
                          return Object.keys(item).length > 0
                        }) === -1 &&
                        this.painan
                      ) {
                        this.painan = false
                        this.updatelist('added')
                        this.reciveList = []
                      }
                      this.operation = false
                      return true
                    }
                  })
              }
            )
          })
        }
        ipt.click()
      }
    },

    uploadPhoto() {
      const ua = navigator.userAgent
      console.log('uploadPhoto..s')
      if (ua === this.$ua.a) {
        // eslint-disable-next-line no-undef
        AND2JS.toAndrPickFile(1)
        return false
      } else if (ua === 'fb-flutter-Webview') {
        console.log('uploadPhoto...flutter')
        window.toAndrPickFile.postMessage('1')
        return false
      } else {
        if (document.getElementById('file')) {
          document.getElementById('file').remove()
        }
        const ipt = document.createElement('input')
        ipt.id = 'file' // 设置ID以便删除
        ipt.type = 'file'
        ipt.multiple = true // 开启支持多选
        ipt.style.display = 'none'
        document.body.appendChild(ipt)
        document.getElementById('file').onchange = () => {
          this.recive = true
          Array.from(ipt.files).forEach((item) => {
            const qiniu = new this.$qiniu()
            const index = this.reciveList.length
            const date = new Date()
            this.reciveList.push({
              filename: item.name,
              time: `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}更新\t\t${(item.size / 1024).toFixed(2)}KB`,
              wdt: '0%',
              $: qiniu,
            })
            qiniu.UploadFile(
              'DEFAULT',
              item,
              {
                next: (file) => {
                  this.$set(this.reciveList[index], 'wdt', file.total.percent + '%')
                },
                complete: () => {
                  this.$set(this.reciveList[index], 'wdt', '100%')
                  this.reciveList[index] = {}
                  if (
                    this.reciveList.filter((item) => {
                      return Object.keys(item).length > 0
                    }).length === 0
                  ) {
                    this.reciveList = []
                  }
                },
                error: () => {
                  this.$toast('上传失败，上传终止')
                  this.reciveList[index] = {}
                },
              },
              (fileopt, key, file) => {
                this.$axios
                  .post(`${caseBase}/management/user/${this.userId2}/collection`, {
                    content: `//oss.farbun.com/${key}`,
                    level: this.dirs.length - 1,
                    name: fileopt.name,
                    size: fileopt.size,
                    parentId: this.dirs[this.dirs.length - 1].id,
                    type: this.getType(gettype(fileopt.name)),
                  })
                  .then((res) => {
                    if (res.data.code === 404) {
                      this.$toast(res.data.message)
                    } else {
                      this.$toast('上传成功')
                      if (
                        this.reciveList.findIndex((item) => {
                          return Object.keys(item).length > 0
                        }) === -1 &&
                        this.painan
                      ) {
                        this.painan = false
                        this.updatelist('added')
                        this.reciveList = []
                      }
                      this.operation = false
                      return true
                    }
                  })
              }
            )
          })
        }
        ipt.click()
      }
    },

    // 接收原生端上传回调
    uploadFilesResultCallBack(data) {
      console.log('skyDrive的原生端回调===>')
      console.log(data)
      const list = JSON.parse(data)
      list.map((item) => {
        this.$axios
          .post(`${caseBase}/management/user/${this.userId2}/collection`, {
            content: item.url,
            level: this.dirs.length - 1,
            name: item.name,
            size: item.size,
            parentId: this.dirs[this.dirs.length - 1].id,
            type: this.getType(gettype(item.name)),
          })
          .then((res) => {
            if (res.data.code === 404) {
              this.$toast(res.data.message)
            } else {
              this.$toast('上传成功')
              if (
                this.reciveList.findIndex((item) => {
                  return Object.keys(item).length > 0
                }) === -1 &&
                this.painan
              ) {
                this.painan = false
                this.updatelist('added')
                this.reciveList = []
              }
              this.operation = false
              return true
            }
          })
      })
    },

    scr() {
      // 筛选文件
      this.screen = !this.screen
      if (this.added) {
        this.added = false
      }
      const fn = (e) => {
        var hehe = document.querySelector('.icon-liebiao1')
        if (hehe) {
          if (!hehe.contains(e.target)) {
            this.screen = false
          }
        }
        document.body.removeEventListener('click', fn)
      }
      setTimeout(() => {
        document.body.addEventListener('click', fn)
      })
    },
    // 打开分析报告预览
    openReportPreview(id) {
      console.log('======>', id)
      this.reportId = id
      this.reportVisible = true
    },
    // 关闭分析报告预览
    closeReportPreview() {
      this.reportId = null
      this.reportVisible = false
    },
    // 初始化状态
    initState() {
      // 如果是分析报告
      if (this.Type === 'REPORT') {
        // ? 不清楚iscollect isMove这两个变量为什么同时设置为true才会显示底部的操作按钮
        this.iscollect = true
        this.isMove = true
        if (this.listid) {
          this.getList('next', {
            type: '',
            parentId: this.listid,
            level: 1,
          })
        } else {
          this.getList('next', {
            type: '',
            parentId: 0,
            level: 0,
          })
        }
      } else if (this.Type === 'SEARCHREPORT') {
        // ? 不清楚iscollect isMove这两个变量为什么同时设置为true才会显示底部的操作按钮
        this.iscollect = true
        this.isMove = true
        if (this.listid) {
          this.getList('next', {
            type: '',
            parentId: this.listid,
            level: 1,
          })
        } else {
          this.getList('next', {
            type: '',
            parentId: 0,
            level: 0,
          })
        }
      } else if (this.listid && this.listname) {
        this.iscollect = true
        this.collection = []
        this.mimitype = null
        setTimeout(() => {
          this.mimitype = ''
        }, 200)
        this.isMove = true
      } else {
        this.getList('next', {
          type: this.mimitype,
          parentId: 0,
          level: 0,
        })
      }
      if (this.$route.query.docid) {
        this.watchDetail = true
      }
    },
    // 获取案件基本信息
    getCaseInfo() {
      this.caseId = this.$route.params.caseId
      if (!this.caseId) {
        return
      }
      this.$axios
        .get(`${this.$base}/lts/case/getCase?caseId=${this.caseId}`)
        .then((res) => {
          // console.log(res)
          if (res.data.code === '200' && res.data.data) {
            if (res.data.data.collectionId) {
              this.query(parseInt(res.data.data.collectionId), res.data.data.caseName, 0, 'FOLDER')
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleSearch(params) {
      if (this.clear) {
        this.$axios
          .get(`${caseBase}/management/user/${this.userId2}/collection?name=${params.name || ''}&page=${this.page}`)
          .then((res) => {
            // 将当前文件夹的id保存起来一边其他地方使用该数据
            this.loading = false
            this.concatData(res)
            setTimeout(() => {
              this.preventUpdate = true
            })
          })
          .catch((err) => {
            this.loading = false
            Promise.reject(err)
          })
      } else {
        this.getList('next', params)
      }
    },
  },
  watch: {
    shoucang(val) {
      this.page = 0
      this.collection = []
      this.getList('next', {
        parentId: this.dirs[this.dirs.length - 1].id,
        level: this.dirs.length - 1,
        type: this.mimitype,
        name: val,
      })
    },
    // 自动检索条件
    ssinput(val) {
      setTimeout(() => {
        this.canScroll = true
        this.hatin = true
      })
      if (val.trim()) {
        this.clear = true
      } else {
        this.clear = false
      }
      this.collection = []
      this.page = 0
      clearTimeout(this.t)
      this.t = setTimeout(() => {
        this.handleSearch({
          parentId: this.dirs[this.dirs.length - 1].id,
          level: this.dirs.length - 1,
          type: this.mimitype,
          name: val.trim(),
        })
      }, 300)
    },
    isShow(val) {
      this.canScroll = true
      this.hatin = true
      this.collection = []
      this.page = 0
      this.getList('next', {
        parentId: this.dirs[this.dirs.length - 1].id,
        level: this.dirs.length - 1,
        type: this.mimitype,
        name: this.ssinput,
      })
    },
    mimitype(val) {
      if (val === null) {
        return
      }
      this.page = 0
      this.getList('next', {
        type: val,
        parentId: this.dirs[this.dirs.length - 1].id,
        level: this.dirs.length - 1,
      })
      this.hatin = true
      this.canScroll = true
      this.collection = []
    },
    // 删除移动操作
    operation(val) {
      if (val) {
        this.collection.forEach((item, index) => {
          this.$set(this.collection[index], 'flag', true)
        })
      } else {
        const index = this.collection.findIndex((item) => {
          return !item.flag
        })
        if (index === -1) {
          this.collection.forEach((item, index) => {
            this.$set(this.collection[index], 'flag', false)
          })
        }
      }
    },
    collection: {
      deep: true,
      handler(val) {
        this.selectItems = []
        let flag = true
        let flag2 = true
        for (let i = 0; i < val.length; i++) {
          const item = val[i]
          if (item.flag) {
            if (!this.isMove) {
              this.tpe = true
            } else {
              this.selectItems.push(item)
              break
            }
            this.selectItems.push(item)
            flag = false
          } else {
            flag2 = true
          }
          if (this.preventUpdate) {
            if (i === val.length - 1 && flag2) {
              const index = val.findIndex((item) => {
                return item.flag
              })
              if (index > -1) {
                this.cancel = true
              } else {
                this.cancel = false
              }
            } else {
              this.cancel = false
            }
            if (
              val.filter((item) => {
                return !item.flag
              }).length === 0
            ) {
              this.cancel = false
            }
          }
        }
        if (flag) {
          this.tpe = false
        }
      },
    },
    selectItems(val) {
      // console.log(val)
      // 如果选中多个文件，不显示下载按钮、移动到回收站
      let copyBtn = true
      let downloadZIPBtn = true
      let downloadBtn = true
      if (val.length > 1) {
        copyBtn = false
        downloadBtn = false
        this.recycleBtn = false
      } else {
        copyBtn = true
        downloadBtn = true
        this.recycleBtn = true
      }
      let deleteBtn = true
      let concatCaseBtn = true
      let moveBtn = true
      let daochuBtn = true
      for (var i = 0; i < val.length; i++) {
        // 如果包含了文件夹，不显示复制功能。
        if (val[i].type === 'FOLDER') {
          copyBtn = false
        }
        // 选中的数据中，如果包含了案件id，则不显示删除、关联案件按钮。
        if (val[i].caseId) {
          deleteBtn = false
          concatCaseBtn = false
        }
        // 选中的数据中，如果isDeletable===false，则不显示删除、移动按钮。
        if (val[i].isDeletable === false) {
          deleteBtn = false
          moveBtn = false
        }
        // 选中的数据中，如果选中多个，且类型是LAW_FRAGMENT或者LEGAL_PRECEDENT_FRAGMENT,则不显示下载，打包下载按钮
        if ((val[i].type === 'LAW_FRAGMENT' || val[i].type === 'LEGAL_PRECEDENT_FRAGMENT' || val[i].type === 'FOLDER' || val[i].type === 'REPORT') && val.length > 1) {
          downloadZIPBtn = false
          downloadBtn = false
        }
        // 选中的数据中，如果选中单个，且类型是LAW_FRAGMENT或者LEGAL_PRECEDENT_FRAGMENT,则不显示打包下载按钮
        if ((val[i].type === 'LAW_FRAGMENT' || val[i].type === 'LEGAL_PRECEDENT_FRAGMENT' || val[i].type === 'FOLDER' || val[i].type === 'REPORT') && val.length === 1) {
          downloadZIPBtn = false
        }

        // 选中的数据中,类型是FOLDER或者LINK,UNKNOWN,则不显示打包下载按钮
        if (
          val[i].type === 'FOLDER' ||
          val[i].type === 'LINK' ||
          val[i].type === 'WEEKREPORT' ||
          val[i].type === 'UNKNOWN' ||
          val[i].type === 'LEGAL_PRECEDENT_FRGMENT' ||
          val[i].type === 'LAW_FRAGMENT'
        ) {
          downloadZIPBtn = false
          downloadBtn = false
        }
        // 如果有文件不是图片，就不显示daochuBtn
        if (val[i].type !== 'IMAGE') {
          daochuBtn = false
        }
      }
      let convertBtnPDF = false
      let convertBtnWORD = false
      if (val.length === 1 && val[0].type === 'WORD') {
        convertBtnPDF = true
      }
      if (val.length === 1 && val[0].type === 'PDF') {
        convertBtnWORD = true
      }
      this.convertBtnPDF = convertBtnPDF
      this.convertBtnWORD = convertBtnWORD
      this.downloadBtn = downloadBtn
      this.downloadZIPBtn = downloadZIPBtn
      this.moveBtn = moveBtn
      this.copyBtn = copyBtn
      this.deleteBtn = deleteBtn
      this.concatCaseBtn = concatCaseBtn
      this.daochuBtn = daochuBtn
    },
  },
  computed: {
    ...mapState('user', {
      userId: (state) => state.userInfo.id,
    }),
    ...mapState('user', ['userInfo']),
    ...mapGetters('user', ['getUserInfo']),
    userId2() {
      return this.getUserInfo.id || null
    },
    showMoveCopyCollect() {
      /* isMove && !iscopy && !iscollect
                ? '移动'
                : iscopy && !iscollect
                ? '复制'
                : iscollect
                ? '收藏'
                : '我的收藏' */
      if (this.isMove && !this.iscopy && !this.iscollect) {
        return '移动'
      } else {
        if (this.iscopy && !this.iscollect) {
          return '复制'
        } else {
          if (this.iscollect) {
            return '收藏'
          } else {
            return '律呗网盘'
          }
        }
      }
    },
    isIOS() {
      return navigator.userAgent === this.$ua.i
    },
  },
  mounted() {
    this.clientHeight = document.documentElement.clientHeight
    this.getCaseInfo()
  },
  created() {
    // 关联组件内方法ocrCaseResultCallback 至 JSBridge/uploadFiles
    this.$hook(this.$bridge.uploadFiles, this.uploadFilesResultCallBack)
    // 组件销毁的时候释放桥接方法
    this.$once('hook:destroyed', () => {
      this.$unhook(this.$bridge.uploadFiles)
    })
    this.initState()
  },
}
</script>
<style lang="stylus" scoped>
.preview
  width 100%
  height 100%
.datail
  width 100%
  height 100%
.link
  width 100%
  height 100%
  .title
    width 100%
    height 40px
    line-height 40px
    border-bottom 1px solid #f3f4f7
    div
      width 91%
      height 100%
      margin 0px auto
      color #666
      span
        margin-left 0.133333rem
        font-size 14px
        font-weight 500
  ul
    width 100%
    height calc(100% - 90px)
    li
      width 100%
      height 35px
      line-height 35px
      border-bottom 1px solid #f3f4f7
      div
        width 91%
        height 100%
        margin 0px auto
        input
          width 100%
          height 90%
          border none
          outline none
  .btn
    width 100%
    height 50px
    display flex
    div
      width 50%
      height 50px
      line-height 50px
      color #ffffff
      text-align center
      font-size 14px
    div:nth-child(1)
      background #b7b8b9
    div:nth-child(2)
      background #4b9efb
.skydrive
  padding-bottom 50px
  width 100%
  height 100%
  background #ffffff
  .title
    width 100%
    height 40px
    line-height 40px
    display flex
    justify-content space-between
    border-bottom 1px solid #F3F4F7
    font-size 14px
    .ipt-content
      width 200px
      height 60%
      align-self center
      line-height 1
      background-color #f6f6f6
      border-radius 999px
      input
        width 100%
        height 100%
        margin 0px
        padding 0px
        border none
        outline none
        background-color #f6f6f6
        border-radius 999px
        text-indent 15px
        font-size 12px
    div:nth-child(1)
      span
        color #666666
        font-weight 500
        margin-left 5px
    div:nth-child(2)
      i
        margin-right 10px
      .icon-p
        color #4B9EFB
      .icon-sousuo
        color #333333
      .icon-liebiao1
        color #333333
        margin-right 0px
      .screen
        position absolute
        width 100%
        box-shadow 0px 0px 7px 3px rgba(240, 240, 240, 1)
        background #ffffff
        border-radius 10px
        text-align center
        right 2px
        z-index 9999999999999
        li
          width 100%
          height 30px
          line-height 30px
      .added
        position absolute
        width 150%
        box-shadow 0px 0px 7px 3px rgba(240, 240, 240, 1)
        background #ffffff
        border-radius 10px
        text-align center
        left -50%
        z-index 10
        li
          width 100%
          height 30px
          line-height 30px
  .route
    width 100%
    overflow hidden
    height 35px
    line-height 35px
    color #666666
    font-size 14px
    padding 0 10px
    box-sizing: border-box
    .routerlist
      overflow hidden
      margin 0px
      padding 0px
      height 35px
      line-height 35px
      overflow-x auto
      overflow-y hidden
      div
        display inline-block
        white-space nowrap
    .route-item
      width 15%
      height 70%
      white-space nowrap
      float left
      display inline-block
      text-align center
      margin-top 5px
      line-height 28px
      position relative
      background-color #4B9EFB
      border-radius 0px 3px 3px 0px
      margin-right 13px
      color white
      text-indent 10px
      .icon-left
        width 0px
        height 0px
        border 14px solid transparent
        position absolute
        top 0px
        left -1px
        border-left-color white
      .icon-right
        width 0px
        height 0px
        border 14px solid transparent
        position absolute
        top 0px
        left calc(100% - 1px)
        border-left-color #4B9EFB
  .shoucang
    width 100%
    height 30px
    line-height 30px
    margin 20px auto 0px
    text-align center
    font-size 14px
    color #666
    font-weight 500x
  .search
    width 91%
    height 26px
    background rgba(242, 242, 242, 1)
    opacity 0.8
    border-radius 13px
    display flex
    text-indent 5px
    margin 7px auto 0px
    .icon-02
      align-self center
      margin-left 5px
    input
      background none
      border none
      outline none
      display inline-block
      width 70%
      height 95%
      text-indent 10px
    .polieer
      width 25%
      height 100%
      display flex
      justify-content space-between
      font-size 13px
      color #4B9EFB
      div:nth-child(1)
        line-height 27px
      .link
        color #CCCCCC
        line-height 27px
      .cancel
        margin-right 6px
        line-height 27px
        color #CCCCCC
  .ShanChuTop
    width 100%
    height 30px
    line-height 30px
    display flex
    margin 0px auto
    box-sizing border-box
    padding 0 10px
    li
      align-self center
    li:nth-child(2)
      margin-left 10px
  .delete
    display flex
    width 100%
    min-height 30px
    line-height 30px
    box-sizing border-box
    padding 0 0px 0 10px
    flex-wrap: wrap;
    li
      color #666666
      font-size 12px
      align-self center
      margin-right 10px
    li:nth-child(2)
      color #000000
  .content
    width 100%
    height calc(100% - 80px)
    overflow auto
    .Vontent
      width 100%
      height 100%
      box-sizing border-box
      padding 0 10px
      li
        width 100%
        height 43px
        line-height 43px
        border-bottom 1px solid #EEEEEE
        display flex
        font-size 14px
        color #000000
        font-weight 400
        .file
          align-self center
        .type
          margin-left 15px
          display flex
          overflow hidden
          width 90%
          span
            max-width 85%
            overflow hidden
            white-space nowrap
            display block
            margin-left 5%
            text-overflow ellipsis
          i
            display block
            width 5%
          .icon-shipinvideo161
            font-size 13px
.typeMiddle
  display flex
  width 70%
  height 100%
  align-items center
.upload
  width 100%
  height 5.333333rem
  position fixed
  bottom 0px
  background-color white
  transition bottom 0.5s
  .icon
    width 100%
    height 20px
    text-align center
    .iconfont
      transition all 0.5s
      font-size 30px
      display inline-block
  .filearea
    width 100%
    height calc(100% - 20px)
    padding 5px
    box-sizing border-box
    overflow auto
    .item
      width 100%
      height 70px
      border 1px solid #CCCCCC
      border-radius 3px
      padding 5px
      margin-top 15px
      .img
        width 50px
        height 40px
        float left
        text-align center
        .iconfont
          font-size 40px
          color #EFA73F
      .info
        width calc(100% - 50px - 10px)
        height 40px
        float right
        font-size 12px
        line-height 20px
        margin-bottom 7px
        .filename
          width 100%
          height 50%
          text-overflow ellipsis
          overflow hidden
        .time
          color #999999
          width 100%
          height 50%
      .jindu
        width 95%
        height 5px
        clear both
        margin 0px auto
        border-radius 999px
        background-color #EEEEEE
        position relative
        .control
          background-color #4B9EFB
          height 100%
          border-radius 999px
          transition all 0.4s
.newFolder
  color #666666
  font-size 14px
  margin-right 10px
.btns_area
  width 100%
  height 50px
  position fixed
  display flex
  font-size 14px
  bottom 0px
  .cancel
    width 50%
    height 100%
    background-color #DDDDDD
    text-align center
    line-height 50px
    color white
  .Yes
    width 50%
    height 100%
    background-color #4B9EFB
    text-align center
    line-height 50px
    color white
.Content
  width 100%
  height 100%
  position fixed
  left 0px
  top 0px
  background-color white
  z-index 20
</style>
