<template>
  <div class="section-panel-comp">
    <div class="panel-header">
      <div class="action">
        <van-checkbox
          value="true"
          shape="square"
          @click="onCheck"
          :disabled="isDisabled"
        />
      </div>
      <div class="title">
        {{ title || '' }}
      </div>
      <div class="loading-status" v-if="loading">
        <van-loading />
      </div>
    </div>
    <div class="panel-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'report-panel',
  props: {
    title: String,
    loading: Boolean
  },
  components: {},
  data() {
    return {}
  },
  computed: {
    isDisabled() {
      return this.loading
    }
  },
  methods: {
    onCheck() {
      this.$emit('checkAll')
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="stylus" scoped>
.section-panel-comp
  position relative
  & .panel-header
    background-color rgba(242, 242, 242, 1)
    font-size 14px
    display flex
    align-items center
    height 30px
    & .title
      flex 1
      color #333
      font-size 14px
      padding-left 6px
    & .action, .loading-status
      width 28px
      display flex
      justify-content center
      align-items center
      margin 0
  & .panel-content
    min-height 100px
</style>
