var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c("portal", { attrs: { to: "fav", order: 2 } }, [
        _c(
          "div",
          { staticClass: "preview-report-comp" },
          [
            _c("div", { staticClass: "iframe-container" }, [
              _vm._isMobile
                ? _c("iframe", {
                    attrs: {
                      src: _vm.src,
                      allowfullscreen: "",
                      id: "myIframe",
                    },
                  })
                : _c(
                    "div",
                    [
                      _c("ana-report", {
                        on: {
                          back: function ($event) {
                            _vm.reportPopupVisible = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
            ]),
            _c("van-nav-bar", {
              attrs: { "left-text": "返回", "left-arrow": "" },
              on: { "click-left": _vm.onClickLeft },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }