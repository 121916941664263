<template>
  <div class="tab-wrap-comp" :class="{ 'is-deleted-tab': isDeleted }">
    <template v-if="!isDeleted">
      <report-header :userInfo="userInfo" :today="today" />

      <report-suggestion @change="emitChange" />
    </template>

    <report-basic @checkAll="checkAll" :isDeleted="isDeleted" v-show="basicDeleted === isDeleted" />

    <div v-for="(comp, i) in queryStatus" :key="comp.type">
      <div
        :is="calcComponentName(comp.type)"
        :attr="comp"
        :isDeleted="isDeleted"
        :type="comp.type"
        :list="getListData(comp.type)"
        :loading="getLoadingStatus(comp.type)"
        v-if="allowRender(i, comp.type)"
        v-show="shouldShowWrap(comp.type)"
        @change="onChange"
        @checkAll="checkAll"
      ></div>
    </div>
  </div>
</template>

<script>
import ReportHeader from './reportHeader'
import ReportSuggestion from './reportSuggestion'
import ReportBasic from './reportBasic'
import chartSection from './chartSection'
import semanticSection from './semanticSection'
import similarSection from './similarSection'
import lawSection from './lawSection'
import simpleSection from './simpleSection'

export default {
  name: 'tab-wrap',
  props: {
    shareUrl: String,
    today: String,
    userInfo: Object,
    queryStatus: Array,
    basicDeleted: Boolean,
    isDeleted: {
      type: Boolean,
      default: () => false,
    },
    listData: Object,
    chartData: Object,
    // 保存后的报告id
    savedReportData: Object,
  },
  components: {
    ReportHeader,
    ReportSuggestion,
    ReportBasic,
    similarSection,
    lawSection,
    simpleSection,
    chartSection,
    semanticSection,
  },
  data() {
    return {}
  },
  computed: {
    isIOS() {
      console.log(navigator.userAgent)
      return navigator.userAgent.includes('iPhone')
    },
  },
  methods: {
    // 全选
    checkAll(payload) {
      this.$emit('checkAll', payload)
    },
    // 计算组件名称
    calcComponentName(type) {
      if (type === 'debate' || type === 'judgment' || type === 'appeal') {
        return 'lawSection'
      } else if (type === 'focus' || type === 'deem') {
        return 'simpleSection'
      } else {
        return type + 'Section'
      }
    },
    // 计算加载状态
    getLoadingStatus(type) {
      if (this.isDeleted) {
        return false
      }
      return !this.queryStatus.find((item) => item.type === type).finished
    },
    // 计算列表数据
    getListData(type) {
      return this.listData[type]
    },
    // 是否渲染
    allowRender(index, type) {
      if (this.isDeleted) {
        if (type === 'semantic' || type === 'chart') {
          return this.listData[type].deleted === this.isDeleted
        } else {
          return this.listData[type].length > 0
        }
      }
      if (index === 0) {
        return true
      } else if (index >= 1) {
        return this.queryStatus[index - 1].finished
      } else {
        return false
      }
    },
    // 传递勾选事件
    onChange(payload) {
      this.$emit('change', payload)
    },
    // 是否显示容器(正在加载或者有子级的时候显示)
    shouldShowWrap(type) {
      // 案由图表直接显示
      if (type === 'semantic' || type === 'chart') {
        return this.listData[type].deleted === this.isDeleted
      } else {
        return this.getLoadingStatus(type) || this.getListData(type).length > 0
      }
    },
    // 传递律师意见变更
    emitChange(payload) {
      this.$emit('suggestionChange', payload)
    },
    // 点击 收藏 按钮
    // emitCreateReport() {
    //   this.$emit('createReport')
    // },
    // 点击 分享 按钮
    // emitShareReport() {
    //   this.$emit('shareReport')
    // },
    // 点击 分享 按钮
    emitDowloadReport() {
      this.$emit('dowloadReport')
    },
  },
}
</script>

<style lang="stylus">
.tab-wrap-comp
  position relative
  min-height calc(100vh - 45px)
  & .is-deleted-tab
    color #999

    & .share
      flex 1
      margin 0 2px
      display flex
      align-items center
      justify-content space-evenly
    & .fav
      flex 1
      margin 0 2px
</style>
