<template>
  <div class="report-law-section-comp">
    <section-panel :title="attr.name" :loading="loading" @checkAll="checkAll">
      <div class="cell-list">
        <law-cell
          v-for="c in list"
          :isDeleted="isDeleted"
          :key="c.id"
          :cellData="c"
          :type="attr.type"
          @change="onChange"
        />
      </div>
    </section-panel>
  </div>
</template>

<script>
import SectionPanel from './sectionPanel'
import LawCell from './lawCell'
export default {
  name: 'law-section',
  props: {
    attr: Object,
    isDeleted: Boolean,
    loading: Boolean,
    list: Array
  },
  components: {
    SectionPanel,
    LawCell
  },
  data() {
    return {}
  },
  computed: {
    title() {
      const s = '使用的法律法规'
      return this.type === 'debate' ? `辩论${s}` : `判决${s}`
    }
  },
  methods: {
    // 全选
    checkAll(payload) {
      const p = {
        isDeleted: this.isDeleted,
        type: this.attr.type
      }
      this.$emit('checkAll', p)
    },
    // 子级单选
    onChange(payload) {
      const c = {
        type: this.attr.type,
        data: payload
      }
      this.$emit('change', c)
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="stylus" scoped>
.report-law-section-comp
  position relative
</style>
