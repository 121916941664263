<template>
  <div class="report-suggestion-comp">
    <div class="hint-text">
      {{ hint }}
    </div>
    <div class="input-field">
      <van-cell-group>
        <van-field v-model="value" @change="emitChange" placeholder="填写律师个人意见" type="textarea" autosize />
      </van-cell-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'report-suggestion',
  props: {},
  components: {},
  data() {
    return {
      hint: '根据您提供的案情，我把裁判文书网全国1.5亿判决书进行了多重维度检索和对比，经过慎重思考后，我认为：',
      value: '',
    }
  },
  computed: {},
  methods: {
    emitChange() {
      this.$emit('change', this.value)
    },
  },
  created() {},
  mounted() {},
}
</script>

<style lang="stylus" scoped>
.report-suggestion-comp
  position relative
  margin-top 6px
  padding 0 6px
  & .hint-text
    color #999999
    font-size 14px
    line-height 24px
  & .input-field
    margin-top 6px
</style>
