var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: `width:100%;height:${_vm.clientHeight}px` },
    [
      _vm.getlist === 1
        ? _c(
            "div",
            { staticClass: "skydrive" },
            [
              _vm.srh
                ? _c(
                    "div",
                    {
                      style: {
                        margin: "0px auto",
                        display: "flex",
                        height: "1.066667rem",
                        "border-bottom": " 1px solid #F3F4F7",
                      },
                    },
                    [
                      !_vm.isSearch
                        ? _c(
                            "div",
                            {
                              staticClass: "search",
                              staticStyle: {
                                top: "0px",
                                border: "none",
                                left: "4%",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.value,
                                    expression: "value",
                                  },
                                ],
                                ref: "sousuo",
                                attrs: { type: "text" },
                                domProps: { value: _vm.value },
                                on: {
                                  blur: _vm.shijiao,
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.value = $event.target.value
                                  },
                                },
                              }),
                              _c("div", { staticClass: "polieer" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { width: "6rem" },
                                    on: { click: _vm.Complete },
                                  },
                                  [_vm._v("确定")]
                                ),
                                _c("div", { staticClass: "link" }, [
                                  _vm._v("|"),
                                ]),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cancel",
                                    staticStyle: { width: "6rem" },
                                    on: { click: _vm.quxiao },
                                  },
                                  [_vm._v("取消")]
                                ),
                              ]),
                            ]
                          )
                        : _c("div", { staticClass: "search" }, [
                            _c("i", { staticClass: "iconfont icon-02" }),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.ssinput,
                                  expression: "ssinput",
                                },
                              ],
                              ref: "ssInt",
                              staticStyle: { width: "80%" },
                              attrs: { type: "text" },
                              domProps: { value: _vm.ssinput },
                              on: {
                                blur: _vm.blur,
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.ssinput = $event.target.value
                                },
                              },
                            }),
                            _vm.clear
                              ? _c("i", {
                                  staticClass: "iconfont icon-clear",
                                  staticStyle: {
                                    "align-self": "center",
                                    "margin-right": "10px",
                                  },
                                  on: { click: _vm.cleardata },
                                })
                              : _vm._e(),
                          ]),
                    ]
                  )
                : _c("div", { staticClass: "title" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          width: "91%",
                          margin: "0px auto",
                        },
                      },
                      [
                        !_vm.iscollect
                          ? _c("div", [
                              _c("i", {
                                staticClass: "iconfont icon-back",
                                on: { click: _vm.back },
                              }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.showMoveCopyCollect)),
                              ]),
                            ])
                          : _c("div", { staticClass: "ipt-content" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.shoucang,
                                    expression: "shoucang",
                                  },
                                ],
                                ref: "shoucang",
                                attrs: {
                                  type: "text",
                                  placeholder: "请选择文件夹",
                                  onkeydown:
                                    "if(event.keyCode===32 && event.target.value.trim().length===0){return false}",
                                },
                                domProps: { value: _vm.shoucang },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.shoucang = $event.target.value
                                  },
                                },
                              }),
                            ]),
                        !_vm.isMove
                          ? _c(
                              "div",
                              {
                                staticClass: "icon_color",
                                style: { position: "relative" },
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    on: {
                                      click: function ($event) {
                                        _vm.photoModle = !_vm.photoModle
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          padding: "0 15px",
                                          "font-style": "normal",
                                          display: "inline-block",
                                          "border-radius": "5px",
                                          color: "#4b9efb",
                                          "font-size": "0.373333rem",
                                        },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.photoModle === false
                                              ? "图"
                                              : "表"
                                          ),
                                        },
                                      },
                                      [_vm._v("图")]
                                    ),
                                  ]
                                ),
                                _c("i", {
                                  staticClass: "iconfont icon-p",
                                  on: { click: _vm.add },
                                }),
                                _c("i", {
                                  staticClass: "iconfont icon-sousuo",
                                  on: { click: _vm.SouSuo },
                                }),
                                _c("i", {
                                  staticClass: "iconfont icon-liebiao1",
                                  on: { click: _vm.scr },
                                }),
                                _vm.screen
                                  ? _c("ul", { staticClass: "screen" }, [
                                      _c(
                                        "li",
                                        {
                                          style: {
                                            "font-weight":
                                              _vm.mimitype === "" ? "700" : "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.mimitype = ""
                                            },
                                          },
                                        },
                                        [_vm._v("全部")]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          style: {
                                            "font-weight":
                                              _vm.mimitype === "FOLDER"
                                                ? "700"
                                                : "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.mimitype = "FOLDER"
                                            },
                                          },
                                        },
                                        [_vm._v("文件夹")]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          style: {
                                            "font-weight":
                                              _vm.mimitype === "IMAGE"
                                                ? "700"
                                                : "IMAGE",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.mimitype = "IMAGE"
                                            },
                                          },
                                        },
                                        [_vm._v(" 图片 ")]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          style: {
                                            "font-weight":
                                              _vm.mimitype === "LINK"
                                                ? "700"
                                                : "LINK",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.mimitype = "LINK"
                                            },
                                          },
                                        },
                                        [_vm._v("链接")]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          style: {
                                            "font-weight":
                                              _vm.mimitype === "LAW_FRAGMENT"
                                                ? "700"
                                                : "LAW_FRAGMENT",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.mimitype = "LAW_FRAGMENT"
                                            },
                                          },
                                        },
                                        [_vm._v(" 判决依据 ")]
                                      ),
                                      _c(
                                        "li",
                                        {
                                          style: {
                                            "font-weight":
                                              _vm.mimitype ===
                                              "LEGAL_PRECEDENT_FRAGMENT"
                                                ? "700"
                                                : "LEGAL_PRECEDENT_FRAGMENT",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.mimitype =
                                                "LEGAL_PRECEDENT_FRAGMENT"
                                            },
                                          },
                                        },
                                        [_vm._v(" 判例片段 ")]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.added
                                  ? _c("ul", { staticClass: "added" }, [
                                      _c("li", { on: { click: _vm.upload } }, [
                                        _vm._v("新建文件夹"),
                                      ]),
                                      _c(
                                        "li",
                                        { on: { click: _vm.uploadfile } },
                                        [_vm._v("上传文件")]
                                      ),
                                      _c(
                                        "li",
                                        { on: { click: _vm.uploadPhoto } },
                                        [_vm._v("上传图片视频")]
                                      ),
                                      _c("li", [_vm._v("新建日程/笔记")]),
                                      _c(
                                        "li",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.getlist = 2
                                              _vm.link_collect = ""
                                              _vm.link_title = ""
                                            },
                                          },
                                        },
                                        [_vm._v(" 新建链接 ")]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                },
                              },
                              [
                                !(_vm.isMove && _vm.iscollect)
                                  ? _c("i", {
                                      staticClass: "iconfont icon-sousuo",
                                      on: { click: _vm.SouSuo },
                                    })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "newFolder",
                                    on: { click: _vm.moveadd },
                                  },
                                  [_vm._v("新建文件夹")]
                                ),
                              ]
                            ),
                      ]
                    ),
                  ]),
              _c(
                "div",
                { staticStyle: { padding: "10px 10px 0 10px" } },
                [
                  _c(
                    "van-checkbox",
                    {
                      attrs: { shape: "square", "icon-size": "14px" },
                      model: {
                        value: _vm.isShow,
                        callback: function ($$v) {
                          _vm.isShow = $$v
                        },
                        expression: "isShow",
                      },
                    },
                    [_vm._v("隐藏空文件夹")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "route" }, [
                _c("div", { staticClass: "routerlist" }, [
                  _c(
                    "ul",
                    { ref: "fq" },
                    _vm._l(_vm.dirs, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.goBack(item.id, item.level)
                            },
                          },
                        },
                        [
                          index !== 0 ? _c("span", [_vm._v("/")]) : _vm._e(),
                          _c("span", [
                            _vm._v(_vm._s(_vm.formDataName(item.name))),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _vm.selectItems.length > 0
                ? _c("ul", { staticClass: "delete" }, [
                    _c(
                      "li",
                      { on: { click: _vm.bootcanp } },
                      [
                        _c("checkbox", {
                          attrs: { cancel: _vm.cancel },
                          model: {
                            value: _vm.operation,
                            callback: function ($$v) {
                              _vm.operation = $$v
                            },
                            expression: "operation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("li", [_vm._v("已选" + _vm._s(_vm.selectItems.length))]),
                    _vm.deleteBtn
                      ? _c("li", { on: { click: _vm.Delete } }, [
                          _vm._v("删除"),
                        ])
                      : _vm._e(),
                    _vm.moveBtn
                      ? _c("li", { on: { click: _vm.move } }, [_vm._v("移动")])
                      : _vm._e(),
                    _vm.copyBtn
                      ? _c("li", { on: { click: _vm.copy } }, [_vm._v("复制")])
                      : _vm._e(),
                    _vm.downloadBtn
                      ? _c("li", { on: { click: _vm.download } }, [
                          _vm._v(
                            " " + _vm._s(_vm.isIOS ? "预览" : "下载") + " "
                          ),
                        ])
                      : _vm._e(),
                    _vm.downloadZIPBtn && !_vm.isIOS
                      ? _c("li", { on: { click: _vm.downloadZIP } }, [
                          _vm._v("打包下载"),
                        ])
                      : _vm._e(),
                    _vm.convertBtnPDF
                      ? _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                _vm.convert((_vm.type = "PDF"))
                              },
                            },
                          },
                          [_vm._v("转PDF")]
                        )
                      : _vm._e(),
                    _vm.convertBtnWORD
                      ? _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                _vm.convert((_vm.type = "WORD"))
                              },
                            },
                          },
                          [_vm._v("转WORD")]
                        )
                      : _vm._e(),
                    _vm.daochuBtn
                      ? _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.daochu(1)
                              },
                            },
                          },
                          [_vm._v("导出到PDF")]
                        )
                      : _vm._e(),
                    _vm.daochuBtn
                      ? _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.daochu(0)
                              },
                            },
                          },
                          [_vm._v("导出到WORD")]
                        )
                      : _vm._e(),
                    _c("li", { on: { click: _vm.share } }, [_vm._v("分享")]),
                  ])
                : _vm._e(),
              !_vm.tpe &&
              !_vm.isMove &&
              !_vm.isSearch &&
              !_vm.iscopy &&
              !_vm.iscollect
                ? _c("ul", { staticClass: "ShanChuTop" }, [
                    _c(
                      "li",
                      { on: { click: _vm.bootcanp } },
                      [
                        _c("checkbox", {
                          attrs: { cancel: _vm.cancel },
                          model: {
                            value: _vm.operation,
                            callback: function ($$v) {
                              _vm.operation = $$v
                            },
                            expression: "operation",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("li", [_vm._v("名称")]),
                  ])
                : _vm._e(),
              _vm.loading
                ? _c("fb-loading", { attrs: { loading: _vm.loading } })
                : !_vm.loading && _vm.collection.length === 0
                ? _c("div", { staticClass: "shoucang" }, [_vm._v("暂无")])
                : _vm._e(),
              _c(
                "div",
                {
                  ref: "scroller",
                  staticClass: "content",
                  on: { scroll: _vm.load },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "Vontent" },
                    [
                      _vm.photoModle
                        ? _vm._l(_vm.collection, function (item, index) {
                            return _c(
                              "li",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.moveList.findIndex((itemr) => {
                                        return itemr.id === item.id
                                      }) === -1,
                                    expression:
                                      "\n              moveList.findIndex((itemr) => {\n                return itemr.id === item.id\n              }) === -1\n            ",
                                  },
                                ],
                                key: index + "info",
                                staticStyle: { height: "2.29334rem" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "file",
                                    on: {
                                      click: function ($event) {
                                        return _vm.change(item, index)
                                      },
                                    },
                                  },
                                  [
                                    _c("checkbox", {
                                      model: {
                                        value: item.flag,
                                        callback: function ($$v) {
                                          _vm.$set(item, "flag", $$v)
                                        },
                                        expression: "item.flag",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "type",
                                    on: {
                                      click: function ($event) {
                                        return _vm.query(
                                          item.id,
                                          item.name,
                                          item.level,
                                          item.type,
                                          item.content
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "typeMiddle",
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          class: ["iconfont", item.icon],
                                          style: {
                                            color: _vm.getcolor(item.icon),
                                          },
                                        }),
                                        _vm.matchType(item.name) != "image"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "max-width": "100%",
                                                  overflow: "hidden",
                                                  "white-space": "nowrap",
                                                  display: "flex",
                                                  "align-items": "center",
                                                  "margin-left": "5%",
                                                  "text-overflow": "ellipsis",
                                                  height: "100%",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      "white-space": "nowrap",
                                                      "max-width": "100%",
                                                      overflow: "hidden",
                                                      "text-overflow":
                                                        "ellipsis",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(item.name) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _c("span", {
                                              style: {
                                                width: "100%",
                                                height: "80%",
                                                background: `url('${item.content}?imageView2/2/h/136')   left center/contain no-repeat`,
                                              },
                                            }),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "typeRight",
                                        staticStyle: {
                                          width: "30%",
                                          height: "100%",
                                          display: "flex",
                                          "align-items": "flex-end",
                                          "flex-direction": "column",
                                          "justify-content": "center",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "typeDate",
                                            staticStyle: {
                                              "text-align": "right",
                                              overflow: "visible",
                                              "max-width": "100%",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.timestampToTime(
                                                    item.updateTime
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("div", {
                                          staticStyle: { width: "100%" },
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.matchType(item.name) ==
                                                "image"
                                                ? `<p class="typeDate"
                 style="text-align:right;max-width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis">
                ${item.name}
              </p>`
                                                : ""
                                            ),
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          })
                        : _vm._l(_vm.collection, function (item, index) {
                            return _c(
                              "li",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.moveList.findIndex((itemr) => {
                                        return itemr.id === item.id
                                      }) === -1,
                                    expression:
                                      "\n              moveList.findIndex((itemr) => {\n                return itemr.id === item.id\n              }) === -1\n            ",
                                  },
                                ],
                                key: index,
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "file",
                                    on: {
                                      click: function ($event) {
                                        return _vm.change(item, index)
                                      },
                                    },
                                  },
                                  [
                                    _c("checkbox", {
                                      model: {
                                        value: item.flag,
                                        callback: function ($$v) {
                                          _vm.$set(item, "flag", $$v)
                                        },
                                        expression: "item.flag",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "type",
                                    on: {
                                      click: function ($event) {
                                        return _vm.query(
                                          item.id,
                                          item.name,
                                          item.level,
                                          item.type,
                                          item.content
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      class: ["iconfont", item.icon],
                                      style: { color: _vm.getcolor(item.icon) },
                                    }),
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                  ]
                                ),
                              ]
                            )
                          }),
                    ],
                    2
                  ),
                ]
              ),
              _vm.reciveList.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "upload",
                      style: { bottom: _vm.recive ? "" : "-4.7rem" },
                    },
                    [
                      _c("div", { staticClass: "icon" }, [
                        _c("i", {
                          staticClass: "iconfont icon-child-on",
                          style: {
                            transform: _vm.recive ? "" : "rotate(-180deg)",
                          },
                          on: {
                            click: function ($event) {
                              _vm.recive = !_vm.recive
                            },
                          },
                        }),
                      ]),
                      _c(
                        "div",
                        { staticClass: "filearea" },
                        _vm._l(_vm.reciveList, function (item, index) {
                          return _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: Object.keys(item).length > 0,
                                  expression: "Object.keys(item).length > 0",
                                },
                              ],
                              key: index,
                              staticClass: "item",
                            },
                            [
                              _c("div", { staticClass: "img" }, [
                                true
                                  ? _c("i", {
                                      staticClass: "iconfont icon-txt",
                                    })
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "info" }, [
                                _c("div", { staticClass: "filename" }, [
                                  _vm._v(_vm._s(item.filename)),
                                ]),
                                _c(
                                  "div",
                                  { staticStyle: { display: "flex" } },
                                  [
                                    _c("div", { staticClass: "time" }, [
                                      _vm._v(_vm._s(item.time)),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleUpload(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconfont icon-shanchu1",
                                          staticStyle: { color: "#ccc" },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "jindu" }, [
                                _c("div", {
                                  staticClass: "control",
                                  style: { width: item.wdt },
                                }),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.watchDetail
                ? _c(
                    "div",
                    { staticClass: "Content" },
                    [
                      _c("precentDetail", {
                        attrs: { id: _vm.$route.query.docid },
                        on: {
                          changeflag: function ($event) {
                            _vm.watchDetail = false
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isMove
                ? _c("div", { staticClass: "btns_area" }, [
                    _c(
                      "div",
                      { staticClass: "cancel", on: { click: _vm.QX } },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "div",
                      { staticClass: "Yes", on: { click: _vm.pushHere } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.iscopy && !_vm.iscollect
                                ? "复制至此"
                                : _vm.isMove && !_vm.iscollect
                                ? "移动至此"
                                : "收藏至此"
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.getlist === 2
        ? _c("div", { staticClass: "link" }, [
            _c("div", { staticClass: "title" }, [
              _c("div", [
                _c("i", {
                  staticClass: "iconfont icon-back",
                  on: { click: _vm.backsky },
                }),
                _c("span", [_vm._v("新建链接")]),
              ]),
            ]),
            _c("ul", [
              _c("li", [
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.link_title,
                        expression: "link_title",
                      },
                    ],
                    attrs: { type: "text", placeholder: "标题" },
                    domProps: { value: _vm.link_title },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.link_title = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
              _c("li", [
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.link_collect,
                        expression: "link_collect",
                      },
                    ],
                    attrs: { type: "text", placeholder: "请输入收藏地址" },
                    domProps: { value: _vm.link_collect },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.link_collect = $event.target.value
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "btn" }, [
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      _vm.getlist = 1
                      _vm.added = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c("div", { on: { click: _vm.link_save } }, [_vm._v("保存")]),
            ]),
          ])
        : _vm._e(),
      _vm.getlist === 3
        ? _c(
            "div",
            { staticClass: "preview" },
            [
              _c("preview", {
                attrs: {
                  id: _vm.numid,
                  userId: _vm.$store.state.user.userInfo.id,
                },
                on: { clearpl: _vm.clearpl },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.getlist === 4
        ? _c(
            "div",
            { staticClass: "datail" },
            [
              _c("law-detail", {
                attrs: { id: _vm.lawid, catlog: _vm.catlog },
                on: {
                  changeflag: function ($event) {
                    _vm.getlist = 1
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.getlist === 5
        ? _c(
            "div",
            { staticClass: "datail" },
            [
              _c("precedentDetail", {
                attrs: { id: _vm.docid },
                on: {
                  changeflag: function ($event) {
                    _vm.getlist = 1
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("preview-report", {
        attrs: { reportId: _vm.reportId, visible: _vm.reportVisible },
        on: { back: _vm.closeReportPreview },
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "top" },
          model: {
            value: _vm.shareDialogVisible,
            callback: function ($$v) {
              _vm.shareDialogVisible = $$v
            },
            expression: "shareDialogVisible",
          },
        },
        [
          _c("div", { staticStyle: { padding: "10px 20px" } }, [
            _c("div", [
              _c(
                "div",
                [
                  _c("span", [_vm._v("分享文件：")]),
                  _vm._l(_vm.selectItems, function (item) {
                    return _c("span", { key: item.id }, [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _c("span", [_vm._v("，")]),
                    ])
                  }),
                  _c("span", [
                    _vm._v("共(" + _vm._s(_vm.selectItems.length) + ")项"),
                  ]),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "margin-top": "20px",
                  },
                },
                [
                  _c("span", [_vm._v("读取密码：")]),
                  _c("van-field", {
                    staticStyle: { width: "100px" },
                    attrs: { maxlength: "4" },
                    model: {
                      value: _vm.sharePassword,
                      callback: function ($$v) {
                        _vm.sharePassword = $$v
                      },
                      expression: "sharePassword",
                    },
                  }),
                ],
                1
              ),
              _c("div", [
                _c(
                  "span",
                  { staticStyle: { color: "#999999", "font-size": "12px" } },
                  [_vm._v("可自定义读取密码，更加隐私安全。")]
                ),
              ]),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "margin-top": "20px",
                  },
                },
                [
                  _c("span", [_vm._v("有效期：")]),
                  _c(
                    "van-radio-group",
                    {
                      attrs: { direction: "horizontal" },
                      model: {
                        value: _vm.effectiveTime,
                        callback: function ($$v) {
                          _vm.effectiveTime = $$v
                        },
                        expression: "effectiveTime",
                      },
                    },
                    _vm._l(_vm.effectiveTimeOptions, function (item) {
                      return _c(
                        "van-radio",
                        { key: item.value, attrs: { name: item.value } },
                        [_vm._v(_vm._s(item.label))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "center",
                    "margin-top": "20px",
                  },
                },
                [
                  _c(
                    "van-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.shareUrl,
                          expression: "!shareUrl",
                        },
                      ],
                      attrs: { type: "info" },
                      on: { click: _vm.getShareUrl },
                    },
                    [_vm._v("生成分享链接")]
                  ),
                  _c(
                    "van-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.shareUrl,
                          expression: "shareUrl",
                        },
                      ],
                      attrs: { type: "info", disabled: "" },
                    },
                    [_vm._v("链接已生成，请粘贴")]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.shareUrl,
                    expression: "shareUrl",
                  },
                ],
              },
              [
                _c(
                  "div",
                  { staticStyle: { color: "#ff6633", "margin-top": "20px" } },
                  [
                    _vm._v(
                      "链接已生成并复制到粘贴板，请直接粘贴到QQ、微信、钉钉。"
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "10px",
                      "font-size": "12px",
                      color: "#999",
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.shareUrl) +
                        "，读取密码：" +
                        _vm._s(_vm.sharePassword) +
                        " "
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "20px",
                          color: "#3399ff",
                          cursor: "pointer",
                        },
                        on: { click: _vm.copyShareUrl },
                      },
                      [_vm._v("复制")]
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }