var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tab-wrap-comp",
      class: { "is-deleted-tab": _vm.isDeleted },
    },
    [
      !_vm.isDeleted
        ? [
            _c("report-header", {
              attrs: { userInfo: _vm.userInfo, today: _vm.today },
            }),
            _c("report-suggestion", { on: { change: _vm.emitChange } }),
          ]
        : _vm._e(),
      _c("report-basic", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.basicDeleted === _vm.isDeleted,
            expression: "basicDeleted === isDeleted",
          },
        ],
        attrs: { isDeleted: _vm.isDeleted },
        on: { checkAll: _vm.checkAll },
      }),
      _vm._l(_vm.queryStatus, function (comp, i) {
        return _c(
          "div",
          { key: comp.type },
          [
            _vm.allowRender(i, comp.type)
              ? _c(_vm.calcComponentName(comp.type), {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.shouldShowWrap(comp.type),
                      expression: "shouldShowWrap(comp.type)",
                    },
                  ],
                  tag: "div",
                  attrs: {
                    attr: comp,
                    isDeleted: _vm.isDeleted,
                    type: comp.type,
                    list: _vm.getListData(comp.type),
                    loading: _vm.getLoadingStatus(comp.type),
                  },
                  on: { change: _vm.onChange, checkAll: _vm.checkAll },
                })
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }