var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-suggestion-comp" }, [
    _c("div", { staticClass: "hint-text" }, [
      _vm._v(" " + _vm._s(_vm.hint) + " "),
    ]),
    _c(
      "div",
      { staticClass: "input-field" },
      [
        _c(
          "van-cell-group",
          [
            _c("van-field", {
              attrs: {
                placeholder: "填写律师个人意见",
                type: "textarea",
                autosize: "",
              },
              on: { change: _vm.emitChange },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }