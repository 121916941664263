var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "law-cell-comp" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("van-checkbox", {
            attrs: { value: true, shape: "square" },
            on: { click: _vm.emitChange },
          }),
          _c(
            "div",
            {
              staticClass: "name van-multi-ellipsis--l3",
              class: {
                "bold-text": _vm.type !== "deem" && _vm.type !== "focus",
              },
            },
            [_vm._v(" " + _vm._s(_vm.cellData.name || "") + " ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "extra-info" },
        [
          _vm.type === "appeal"
            ? [
                _vm.cellData.case_name
                  ? _c("div", { staticClass: "van-ellipsis" }, [
                      _vm._v(" " + _vm._s(_vm.cellData.case_name) + " "),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "van-ellipsis",
                    attrs: { "cellData.court_name": "" },
                  },
                  [
                    _vm._v(" " + _vm._s(_vm.cellData.court_name)),
                    _vm.cellData.pub_date
                      ? _c("span", [
                          _vm._v("/ " + _vm._s(_vm.cellData.pub_date)),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            : _vm._e(),
          _vm.type !== "appeal"
            ? [
                _vm.cellData.case_name
                  ? _c("div", { staticClass: "van-ellipsis" }, [
                      _vm._v(" " + _vm._s(_vm.cellData.case_name) + " "),
                    ])
                  : _vm._e(),
                _vm.cellData.case_id
                  ? _c("div", { staticClass: "van-ellipsis" }, [
                      _vm._v(" " + _vm._s(_vm.cellData.case_id) + " "),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
      _vm.isLawAndHasChildren
        ? [
            _c(
              "div",
              { staticClass: "children-list" },
              _vm._l(_vm.getChildrenData(_vm.cellData.children), function (c) {
                return _c("law-child", {
                  key: c.id,
                  attrs: { type: _vm.type, cellData: c },
                  on: { change: _vm.emitChildChange },
                })
              }),
              1
            ),
          ]
        : [
            _c("div", { staticClass: "content van-multi-ellipsis--l3" }, [
              _c("span", [_vm._v(_vm._s(_vm.cellData.content || ""))]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }