var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "report-summary-comp" },
    [
      _c(
        "section-panel",
        { attrs: { title: _vm.title }, on: { checkAll: _vm.checkAll } },
        [
          _c(
            "div",
            { staticClass: "ana-text" },
            [
              _c("van-field", {
                attrs: {
                  placeholder: "案情概要",
                  type: "textarea",
                  autosize: "",
                },
                on: { change: _vm.change },
                model: {
                  value: _vm.copyText,
                  callback: function ($$v) {
                    _vm.copyText = $$v
                  },
                  expression: "copyText",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }