<template>
  <div class="ana-report-page" ref="report">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" @click-right="onClickRight" />
    <van-tabs v-model="activeTabName" class="no-tab-title" swipeable animated>
      <van-tab name="use">
        <tab-wrap
          :queryStatus="queryStatus"
          :basicDeleted="basicDeleted"
          :listData="listData"
          :savedReportData="savedReportData"
          :userInfo="userData"
          :lawyerSuggestion="lawyerSuggestion"
          :today="today"
          :shareUrl="shareUrl"
          @change="handleChange"
          @checkAll="handleCheckAll"
          @createReport="createReport"
          @shareReport="shareReport"
          @dowloadReport="dowloadReport"
        />
      </van-tab>
      <van-tab name="delete">
        <tab-wrap :isDeleted="true" :basicDeleted="basicDeleted" :listData="listDataDeleted" :queryStatus="queryStatus" @change="handleChangeFromDeleted" @checkAll="handleCheckAll" />
      </van-tab>
    </van-tabs>
    <fb-notify :msg="actionNotifyMsg" @linkClick="onNotifyLinkClick" :visible="actionNotifyVisible" />
    <portal to="fav" v-if="favVisible" :order="1">
      <transition name="van-slide-down">
        <div class="full-screen-portal-comp">
          <skyDrive ref="skyDrive" :listid="favFolderId" Type="REPORT" @CancelCollect="cancelFav" :modulename="'分析报告'" :listname="null" :summary="summary" @getId="useFavId"></skyDrive>
        </div>
      </transition>
    </portal>
    <div class="create-report-btn" v-show="aboveDataLoaded">
      <!--   @click="shareReport" -->
      <div class="share">
        <van-button type="default" class="shareBtn" :data-clipboard-text="shareUrl" plain @click="shareReport" hairline block> <van-icon color="#249eff" name="share" /> 转发到微信</van-button>
        <!-- <van-button type="info" v-if="!isIOS" @click="emitDowloadReport" plain hairline block>下载</van-button> -->
      </div>
      <div class="fav">
        <van-button type="default" @click="createReport" block plain hairline><van-icon name="star" color="#249eff" />收藏到网盘</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { genID } from '@/common/utils'
import clipboard from 'clipboard'

import TabWrap from './components/tabWrap'
import skyDrive from '@/pages/skyDrive/index'

export default {
  comment: '分析报告组件',
  name: 'ana-report',
  props: {
    // 类型 0-民事 1-刑事
    isType: {
      type: Number,
      default: () => 0,
    },
  },
  components: {
    TabWrap,
    skyDrive,
  },
  data() {
    return {
      shareUrl: '',
      title: '分析报告',
      activeTabName: 'use',
      // 案情概要 对应store-ana/text
      basicDeleted: false,
      // 律师意见
      lawyerSuggestion: '',
      // 收藏组件开关
      favVisible: false,
      // 收藏的文件夹id 用于查看报告
      favFolderId: null,
      // 获取数据的顺序
      queryStatus: [
        {
          type: 'semantic',
          name: '分析案由及其胜败比率',
          finished: false,
        },
        { type: 'chart', name: '诉讼时间分析', finished: false },
        { type: 'judgment', name: '可能涉及的法律法规', finished: false },
        {
          type: 'appeal',
          name: '相似判例及其诉讼请求裁判结果',
          finished: false,
        },
        { type: 'focus', name: '争议焦点', finished: false },
      ],
      // 分析案由及其胜败比率
      semantic: {
        title: null,
        num: null,
        deleted: false,
      },
      // 诉讼时间分析 图表数据
      chart: {
        month: [],
        year: [],
        deleted: false,
      },
      judgment: [],
      focus: [],
      appeal: [],
      // 生成报告成功后 接口返回的数据
      savedReportData: null,
      // 消息通知组件显示开关
      actionNotifyVisible: false,
      // 生成报告后的提示消息
      actionNotifyMsg: '',
      divorceDataMap: {},
    }
  },
  computed: {
    ...mapState('user', ['userInfo', 'organization']),
    ...mapGetters('user', ['userId']),
    ...mapState('ana', ['text', 'summary', 'anaTwoKeyDown']),
    userData() {
      return { ...this.userInfo, organization: this.organization }
    },
    aboveDataLoaded() {
      const f = (v) => {
        return v.finished
      }
      return this.queryStatus.every(f)
    },
    // userAgent
    ua() {
      return navigator.userAgent
    },
    today() {
      return window.moment(Date.now()).format('YYYY.MM.DD')
    },
    // 选中页面的列表数据
    listData() {
      const o = {}
      this.queryStatus.forEach((item) => {
        const t = item.type
        if (t) {
          o[t] = this.getListData(t)
        }
      })
      return o
    },
    // 删除页面的列表数据
    listDataDeleted() {
      const o = {}
      this.queryStatus.forEach((item) => {
        const t = item.type
        if (t) {
          o[t] = this.getListDataDeleted(t)
        }
      })
      return o
    },
  },
  methods: {
    onClickLeft() {
      // console.log('返回')
      this.$emit('back')
    },
    onClickRight() {
      console.log('编辑')
    },
    // 律师意见改变
    onLawyerSuggestionChange(v) {
      this.lawyerSuggestion = v
    },

    // 模式请求数据
    async queryData(type) {
      const p = await new Promise((resolve, reject) => {
        const data = this.genMockData(5, type)
        setTimeout(() => {
          resolve({
            type,
            data,
          })
        }, 2e3)
      })
      return p
    },
    // 处理全选
    // ! summary semantic chart 三种类型特殊处理
    handleCheckAll(payload) {
      const { type, isDeleted } = payload
      console.log('checkAll', payload)
      if (type === 'basic') {
        this.basicDeleted = !isDeleted
      } else if (type === 'chart' || type === 'semantic') {
        this[type] = { ...this[type], deleted: !isDeleted }
      } else {
        this[type] = this.reverseDeletedStatus(this[type], !isDeleted)
      }
    },
    // 删除状态反转 用于全选
    reverseDeletedStatus(arr, isDeleted) {
      return arr.map((item) => {
        const temp = {
          ...item,
          deleted: isDeleted,
        }
        if (item.children && item.children.length) {
          temp.children = this.reverseDeletedStatus(temp.children, isDeleted)
        } else {
          temp.children = null
        }
        return temp
      })
    },
    // 对数据进行转换
    // ! 添加id和deleted字段 将需要展示的字段放到name和content里 类似标题+内容模式
    transformData(type, arr) {
      if (!arr.length) {
        return []
      }
      if (type === 'similar') {
        return arr.map((item) => {
          return {
            ...item,
            id: genID(),
            deleted: false,
            name: item.case_name,
            content: item.text,
          }
        })
      } else if (type === 'judgment' || type === 'debate') {
        return arr.map((item) => {
          const temp = {
            ...item,
            id: genID(),
            deleted: false,
            content: null,
          }
          if (item.data && item.data) {
            temp.children = item.data.map((elem) => {
              return {
                ...elem,
                id: genID(),
                deleted: false,
                name: elem.title,
                content: elem.text,
              }
            })
          }
          return temp
        })
      } else if (type === 'focus') {
        return arr.map((item) => {
          const t = item.case[0] || {}
          return {
            ...t,
            id: genID(),
            deleted: false,
            name: t.text,
          }
        })
      } else if (type === 'deem') {
        return arr.map((item) => {
          return {
            ...item,
            id: genID(),
            deleted: false,
            name: item.text,
          }
        })
      } else if (type === 'appeal') {
        return arr.map((item) => {
          const temp = {
            ...item,
            id: genID(),
            name: item.case_name,
          }
          if (item.data && item.data.length) {
            temp.children = item.data.map((elem) => {
              return {
                ...elem,
                id: genID(),
                deleted: false,
                name: elem.title,
                content: elem.data,
              }
            })
          }
          return temp
        })
      }
    },
    // 根据给定的顺序获取数据
    async queryDataByOrder() {
      const param = {
        sentence: this.text || '离婚',
        summary: this.summary || '离婚纠纷',
        input_keyword: this.anaTwoKeyDown || [],
        is_type: this.isType,
        key: 'law',
        condition: '',
        offset: 1,
      }
      for (const t of this.queryStatus) {
        // 可能使用的法律法规- 实体法+探索法
        // ! semantic 和 chart 这两个数据类型为Object 其他为Array
        const { type } = t
        let data = []
        if (type === 'semantic') {
          data = await this.getSematic(
            {
              sentence: param.sentence,
              input_keyword: param.input_keyword,
              is_type: param.is_type,
              user_id: this.userInfo.id,
            },
            param.summary
          )
          this[type] = { ...data, deleted: false }
        } else if (type === 'chart') {
          // 诉讼时间分析图表数据
          const litigation = await this.getLaw({ ...param, key: 'litigation' })
          if (litigation.datas && litigation.datas.length) {
            const { time, year } = litigation.datas[0]
            this[type] = {
              month: time.month,
              year: year.year,
              deleted: false,
            }
            // 只取前十条
            const courtName = {
              data: litigation.datas[5].court_name_report.data.slice(0, 10),
              title: litigation.datas[5].court_name_report.title.slice(0, 10),
            }
            this.divorceDataMap = {
              judgeYear: litigation.datas[1].judge_year_report,
              province: litigation.datas[2].province_report,
              proceeding: litigation.datas[3].proceeding_report,
              courtLevel: litigation.datas[4].court_level_report,
              courtName: courtName,
              deleted: false,
            }
          }
        } else if (type === 'judgment') {
          data = await this.getLaw(param)
          const { datas } = data
          if (datas && datas.length) {
            // 实体法-entity
            const entity = datas.find((item) => item.key === 'entity')
            if (entity) {
              entity.values.forEach((item) => {
                const childrenList = []
                item.data.forEach((children, index) => {
                  childrenList.push(children)
                  if (children.child_list) {
                    children.child_list.forEach((item1) => {
                      if (item1.child) {
                        item1.child.forEach((item2) => {
                          let title = '新' + ' ' + item1.law_title
                          if (item2.title) {
                            title = title + item2.title
                          }
                          var obj = {
                            law_id: item1.law_id,
                            title: title,
                            text: item2.text ? item2.text : '',
                            colorType: true,
                          }
                          childrenList.push(obj)
                        })
                      }
                    })
                    const key = index + '_' + 'relevancy'
                    let len = 0
                    children.law_relevancy.forEach((item1) => {
                      if (item1.child) {
                        item1.child.forEach((item2) => {
                          len++
                        })
                      }
                    })
                    let i = 0
                    children.law_relevancy.forEach((item1) => {
                      if (item1.child) {
                        item1.child.forEach((item2) => {
                          let title = '关联' + ' ' + item1.law_title
                          if (item2.title) {
                            title = title + item2.title
                          }
                          var obj = {
                            law_id: item1.law_id,
                            title: title,
                            text: item2.text ? item2.text : '',
                            colorType: true,
                            show: false,
                            relevancyKey: key,
                            len: len,
                            i: i,
                          }
                          if (i === 0) {
                            childrenList.push(obj)
                          }
                          i++
                        })
                      }
                    })
                  }
                })
                // console.log(childrenList)
                item.data = childrenList
              })
            }

            // 探索法-debate
            const entitycaipanyiju = datas.find((item) => item.key === 'entity_caipanyiju')
            if (entity) {
              this[type] = this.transformData(type, [...entity.values, ...entitycaipanyiju.values])
            } else {
              this[type] = this.transformData(type, [...entitycaipanyiju.values])
            }
          }
        } else if (type === 'focus' || type === 'deem' || type === 'appeal') {
          data = await this.getLaw({ ...param, key: type })
          const { datas } = data
          if (datas && datas.length) {
            this[type] = this.transformData(
              type,
              datas.filter((item, i) => i < 10)
            )
          }
        }
        this.updateQueryStatus(type)
      }
    },
    // 更新数据状态
    updateQueryStatus(type) {
      this.queryStatus = this.queryStatus.map((item) => {
        if (item.type === type) {
          const temp = {
            ...item,
            finished: true,
          }
          return temp
        } else {
          return item
        }
      })
    },
    // 是否包含删除的子级
    hasDeletedChildren(law) {
      if (!law.children || law.children.length === 0) {
        return false
      } else {
        const f = (v) => {
          return v.deleted
        }
        return law.children.some(f)
      }
    },
    // 是否全是删除的子级
    hasDeletedAllChildren(law) {
      if (!law.children || law.children.length === 0) {
        return false
      } else {
        const f = (v) => {
          return v.deleted
        }
        return law.children.every(f)
      }
    },
    // 更新单元数据状态 -- 标记删除
    updateCellItemStatus({ type, data }, isDeleted = true) {
      const list = this[type]
      // 将数组中符合条件的元素 标记为指定状态
      const updateFn = (arr, obj) =>
        arr.map((item) => {
          if (obj.id === item.id) {
            return {
              ...item,
              deleted: isDeleted,
            }
          } else {
            return item
          }
        })
      // 将数组全都标记为指定状态 （主要用于全选子级）
      const updateAllFn = (arr) =>
        arr.map((item) => {
          return {
            ...item,
            deleted: isDeleted,
          }
        })
      // 更新外层数据的子级
      const updateChildrenFn = (arr, obj, children) =>
        arr.map((element) => {
          if (element.id === obj.id) {
            return {
              ...obj,
              children,
            }
          } else {
            return element
          }
        })
      // 判断点击的类型是否为 #法律法规#
      // 如果有子级则为法律法规且没有子级则表示是子级（第n条）
      if (type === 'judgment' || type === 'appeal') {
        // 有子级表示点击的是法律标题 需要操作法律所有的状态
        if (data.children) {
          const newChildren = updateAllFn(data.children)
          const withChildrenChanged = updateChildrenFn(list, data, newChildren)
          this[type] = updateFn(withChildrenChanged, data)
        } else {
          const { parent, self } = data
          const children = updateFn(parent.children, self)
          this[type] = updateChildrenFn(list, parent, children)
        }
      } else {
        this[type] = updateFn(list, data)
      }
    },
    // 选中页面的勾选操作
    handleChange(payload) {
      const { type, data } = payload
      if (type && data) {
        this.updateCellItemStatus(payload, true)
      }
    },
    // 删除页面的勾选操作
    handleChangeFromDeleted(payload) {
      const { type, data } = payload
      if (type && data) {
        this.updateCellItemStatus(payload, false)
      }
    },
    // 计算出选中的数据
    getListData(type) {
      if (type === 'semantic' || type === 'chart') {
        return this[type]
      } else {
        return this[type].filter((item) => {
          return !item.deleted && !this.hasDeletedAllChildren(item)
        })
      }
    },
    // 计算出删除的数据
    getListDataDeleted(type) {
      if (type === 'semantic' || type === 'chart') {
        return this[type]
      } else {
        return this[type].filter((item) => {
          return item.deleted || this.hasDeletedChildren(item)
        })
      }
    },
    // 相似判例
    async getSimilar(param) {
      const { data } = await this.$axios.post(`${this.$pydataBase}/api/ai/similar`, param)
      if (data.code === 200 && data.result) {
        // 只取前10条
        return data.result.filter((item, i) => i < 10)
      } else {
        return []
      }
    },
    // 获取案由
    // @param param: { sentence!: string,  user_id?: string,  input_keyword?: string[]}
    // @param summary？: string （案由）
    async getSematic(param, summary) {
      const { data } = await this.$axios.post(`${this.$pydataBase}/api/ai/semantic`, param)
      if (data.code === 200 && data.graph) {
        return data.graph.find((item) => item.name === summary)
      } else {
        return []
      }
    },
    // 法律依据
    async getLaw(param) {
      const { data } = await this.$axios.post(`${this.$pydataBase}/api/ai/semantic/category`, param)
      if (data.code === 200 && data.result) {
        return data.result
      } else {
        return []
      }
    },
    // 数据瘦身
    slimData(listObj) {
      const s = (arr) => {
        if (arr.length) {
          return arr.map((item) => {
            const { id, name, content, deleted, case_name: caseName, case_id: caseId, pub_date: pubDate, court_name: courtName } = item
            const temp = { id, name, content, deleted }
            if (caseName) {
              temp.case_name = caseName
            }
            if (caseId) {
              temp.case_id = caseId
            }
            if (pubDate) {
              temp.pub_date = pubDate
            }
            if (courtName) {
              temp.court_name = courtName
            }
            if (item.children && item.children.length) {
              const c = s(item.children)
              temp.children = c
            }
            return temp
          })
        } else {
          return []
        }
      }
      const t = {}
      Object.keys(listObj).forEach((key) => {
        if (key === 'chart' || key === 'semantic') {
          t[key] = listObj[key]
        } else {
          t[key] = this.trimDeleted(s(listObj[key]))
        }
      })
      return t
    },
    // 去除标记为删除的数据
    trimDeleted(arr) {
      const firstLevel = arr.filter((item) => !item.deleted)
      const newArr = firstLevel.map((item) => {
        const temp = { ...item }
        if (item.children && item.children.length) {
          temp.children = item.children.filter((item) => !item.deleted)
        }
        return temp
      })
      return newArr
    },
    // 获取 创建分析报告需要的id （通过收藏实现）
    async getReportSaveId() {
      console.log('getReportSaveId')
      const { data } = await this.$axios.get(`${this.$base}/management/users/${this.userId}`)
      if (data.code === 200 && data.data) {
        return data.data.reportscollectionId || data.data.temporaryCollectionId
      } else {
        return null
      }
    },
    // 创建报告
    async createReport() {
      // 打开收藏组件
      this.favVisible = true
    },
    // 分享报告 如果还未生成报告则直接保存到分析报告文件夹或者临时文件夹
    async shareReport(type) {
      const that = this
      if (type === 'getShareUrl') {
        // if (!this.savedReportData) {
        const folderId = await this.getReportSaveId()
        const data = await this.createReportWithFolderId(folderId, false)
        if (data) {
          this.savedReportData = data
        }
        // }
        const { name, id } = this.savedReportData
        if (name && id) {
          this.shareUrl = `https://tool.farbun.com/shared/report/${id}`
          if (this.$base === 'https://api.dev.farbun.com') {
            this.shareUrl = `https://test.farbun.com/shared/report/${id}`
          }
        }
      } else {
        // if (!this.savedReportData) {
        const folderId = await this.getReportSaveId()
        const data = await this.createReportWithFolderId(folderId, false)
        if (data) {
          this.savedReportData = data
        }
        // }
        const { name, id } = this.savedReportData
        if (name && id) {
          const ua = this.ua
          this.shareUrl = `https://tool.farbun.com/shared/report/${id}`
          if (this.$base === 'https://api.dev.farbun.com') {
            this.shareUrl = `https://test.farbun.com/shared/report/${id}`
          }
          if (ua === this.$ua.a) {
            // eslint-disable-next-line no-undef
            AND2JS.shareCaseFile(name, window.encodeURI(this.shareUrl))
            console.log('a')
          } else if (this.ua === 'fb-flutter-Webview') {
            window.shareCaseFile.postMessage(
              JSON.stringify({
                filename: '案情分析报告',
                description: '案情分析报告',
                url: window.encodeURI(this.shareUrl),
              })
            )
          } else {
            // eslint-disable-next-line new-cap
            const Cb = new clipboard('.shareBtn')
            Cb.on('success', function (e) {
              console.log('成功')
              e.clearSelection()
            })
            Cb.on('error', function (e) {
              console.log('失败', e)
            })
            that.$notify({
              type: 'success',
              message: '已复制本网页地址，请粘贴到QQ或微信中',
            })
            console.log('cb')
          }
        } else {
          this.$notify('未能获取到需要的数据')
        }
      }
    },

    // 格式化时间 年-月-日 时：分：秒
    formatDate(fmt, date) {
      let ret
      const opt = {
        'Y+': date.getFullYear().toString(), // 年
        'm+': (date.getMonth() + 1).toString(), // 月
        'd+': date.getDate().toString(), // 日
        'H+': date.getHours().toString(), // 时
        'M+': date.getMinutes().toString(), // 分
        'S+': date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(ret[1], ret[1].length === 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'))
        }
      }
      return fmt
    },
    // 下载分析报告
    dowloadReport() {
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      })
      let divorceGraph = null
      // 饼状图
      if (!this.semantic.deleted) {
        divorceGraph = {
          num: this.semantic.num,
          title: this.semantic.title,
        }
      }
      // 时间戳
      // const date = this.formatDate('YYYY年mm月dd日HH点MM分SS', new Date())
      // console.log('时间戳:', date)

      // 争议焦点集合
      const divorceControversials = []
      this.focus.map((item) => {
        if (!item.deleted) {
          var obj = {
            // doc_id: item.doc_id,
            text: item.text,
            case_name: item.case_name,
            case_id: item.case_id,
            court_name: item.court_name,
            pub_date: item.pub_date,
          }
          divorceControversials.push(obj)
        }
      })
      // 判例使用的法律法规
      const divorceEntities = []
      this.judgment.map((item) => {
        var obj = {}
        if (!item.deleted) {
          obj.name = item.name
          obj.divorceCataloguess = []
          item.children.map((childItem) => {
            if (!childItem.deleted) {
              var itemObj = {
                text: childItem.text,
                title: childItem.title,
              }
              obj.divorceCataloguess.push(itemObj)
            }
          })
          divorceEntities.push(obj)
        }
      })
      // 判例使用的法律法规
      console.log(this.appeal)
      const divorceSimilarity = []
      this.appeal.map((item) => {
        var obj = {}
        if (!item.deleted) {
          obj.case_id = item.case_id
          obj.case_name = item.case_name
          obj.court_name = item.court_name
          obj.pub_date = item.pub_date
          obj.divorceCases = []
          item.children.map((childItem) => {
            if (!childItem.deleted) {
              var itemobj = {}
              itemobj.title = childItem.title
              itemobj.datas = childItem.data
              obj.divorceCases.push(itemobj)
            }
          })
          divorceSimilarity.push(obj)
        }
      })
      let divorceReportData = null
      if (!this.chart.deleted) {
        // 柱状图
        divorceReportData = {
          moths: this.chart.month.title,
          mothsData: this.chart.month.data,
          years: this.chart.year.title,
          yearsData: this.chart.year.data,
        }
      }
      let divorceDataMap = null
      if (!this.divorceDataMap.deleted) {
        divorceDataMap = {
          areaNum: {
            names: this.divorceDataMap.province.title,
            values: this.divorceDataMap.province.data,
          },
          proceeding: {
            names: this.divorceDataMap.proceeding.title,
            values: this.divorceDataMap.proceeding.data,
          },
          courtLever: {
            names: this.divorceDataMap.courtLevel.title,
            values: this.divorceDataMap.courtLevel.data,
          },
          trialCourt: {
            names: this.divorceDataMap.courtName.title,
            values: this.divorceDataMap.courtName.data,
          },
          trialYears: {
            names: this.divorceDataMap.judgeYear.title,
            values: this.divorceDataMap.judgeYear.data,
          },
        }
      }
      const parmas = {
        divorceGraph: divorceGraph,
        divorceReportData: divorceReportData,
        divorceControversials: divorceControversials,
        divorceEntities: divorceEntities,
        divorceSimilarity: divorceSimilarity,
        sentence: this.text || '离婚',
        summary: this.summary || '离婚纠纷',
        nickName: this.userInfo.nickName,
        lawyerSuggestion: this.lawyerSuggestion,
        divorceDataMap: divorceDataMap,
      }
      this.$axios({
        method: 'POST',
        url: `${this.$base}/document/convertoffice/DowAnalysis2`,
        data: parmas,
        dataType: 'JSONP',
        // responseType: 'blob'
      })
        .then((res) => {
          // const blob = new Blob([res.data], { type: 'application/msword' }) // res就是接口返回的文件流了
          // const objectUrl = URL.createObjectURL(blob)
          // // window.location.href = objectUrl
          // const link = document.createElement('a')
          // const fname = '案情分析报告' // 下载文件的名字
          // link.href = objectUrl
          // link.setAttribute('download', fname)
          // document.body.appendChild(link)
          // link.click()
          if (res.data.code === '200' && res.data.data) {
            const url = res.data.data
            const name = url.substring(url.lastIndexOf('/') + 1)
            const pathUrl = url.substring(0, url.lastIndexOf('/'))
            const ua = navigator.userAgent
            if (ua === this.$ua.a) {
              // eslint-disable-next-line
              AND2JS.showFilePreview(url)
            } else if (ua === 'fb-flutter-Webview') {
              // eslint-disable-next-linetodo0927
              try {
                // 之前的方法
                // window.fbDownloadFile.postMessage(res.data.data)
                // 新改的方法
                window.showFilePreview.postMessage(
                  JSON.stringify({
                    filePath: pathUrl,
                    fileName: name,
                  })
                )
              } catch (error) {
                console.log(error, 'fbDownloadFile.postMessage')
              }
            } else if (ua === this.$ua.i) {
              // ios环境
              // eslint-disable-next-line
              window.webkit.messageHandlers.fbDownloadFile.postMessage(url)
            } else {
              console.log('非原生端', url)
            }
            // window.location.href = res.data.data
          } else {
            this.$notify('下载失败')
          }
          this.$toast.clear()
        })
        .catch((err) => {
          console.log('catch-err', err)
          this.$notify('下载失败')
          this.$toast.clear()
        })
    },
    // 显示操作提示
    showActionSuccessNotify(data) {
      // if (this.timer) {
      //   clearTimeout(this.timer)
      // }
      // this.savedReportData = data
      // this.actionNotifyMsg = {
      //   text: '生成报告成功',
      //   link: '点击查看',
      // }
      // this.actionNotifyVisible = true
      // this.timer = setTimeout(() => {
      //   this.actionNotifyVisible = false
      // }, 5e3)
    },
    // 提示组件的 点击查看
    onNotifyLinkClick() {
      this.openFavPopup()
    },
    // 打开收藏组件弹层
    openFavPopup() {
      this.favVisible = true
    },
    // 关闭收藏组件弹层
    closeFavPopup() {
      this.favVisible = false
    },
    // 取消收藏
    cancelFav() {
      this.closeFavPopup()
    },
    // 使用文件夹id生成报告（将报告保存到该文件夹下）
    async createReportWithFolderId(folderId, notify = true) {
      const url = `${this.$base}/management/user/${this.userId}/collection`
      const { nickName, organization } = this.userData
      const organizationName = organization && organization.name ? organization.name : ''
      const time = window.moment(Date.now()).format('YYYY.MM.DD-hh.mm')
      const name = `${nickName} ${organizationName} 案件分析报告  ${this.summary} ${time}`
      const parentId = folderId
      const chart = this.chart.deleted ? null : this.chart
      const semantic = this.semantic.deleted ? null : this.semantic
      const section = this.slimData({ ...this.listData, chart, semantic })
      const divorceDataMap = this.divorceDataMap.deleted ? null : this.divorceDataMap
      section.divorceDataMap = divorceDataMap
      console.log(section)
      const contentToSave = {
        // 律师意见
        lawyerSuggestion: this.lawyerSuggestion,
        // 用户信息
        user: this.userData,
        // 创建日期
        createDate: this.today,
        // 案情概要
        basic: this.basicDeleted ? null : this.text,
        // 区块数据
        section,
      }
      const content = JSON.stringify(contentToSave)
      const d = {
        type: 'REPORT',
        name,
        level: 1,
        parentId,
        content,
      }
      try {
        const { data } = await this.$axios.post(url, d)
        if (data.code === 200) {
          return data.data
        } else {
          return null
        }
      } catch (err) {
        this.$notify('生成报告失败')
        console.log(err)
      }
    },
    // 收藏组件点击 #收藏至此# 取得上级文件夹id
    async useFavId(parentId) {
      if (!parentId) {
        return this.$notify('请新建文件夹收藏')
      }
      this.favFolderId = parentId
      // this.closeFavPopup()
      const data = await this.createReportWithFolderId(parentId)
      if (data) {
        console.log(data, 'data')
        this.savedReportData = data

        this.$refs.skyDrive.showShouCangToast()
        // this.showActionSuccessNotify()
      }
    },
  },
  async created() {
    this.queryDataByOrder()
    // this.getReportSaveId()
    // this.createReport()
  },
  mounted() {
    this.shareReport('getShareUrl')
  },
  destroyed() {
    this.favFolderId = null
    this.actionNotifyMsg = ''
  },
  watch: {
    favVisible(val) {
      // 处理移动端fixed布局滚动穿透
      if (val) {
        document.body.classList.add('noscroll')
      } else {
        document.body.classList.remove('noscroll')
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
 .create-report-btn
    position sticky
    bottom 0
    width 100vw
    margin-top 20px
    display flex
    align-items center
    justify-content space-evenly
    background white
    border 1px solid #457af7
   & .share
      width 49%
      border-right 1px solid #eeeeee
   & .fav
      width 49%
/deep/
.van-tabs.no-tab-title
  & .van-tabs__wrap
    height 0
.full-screen-portal-comp
  position fixed
  top 0
  left 0
  width 100vw
  height 100vh
  z-index 2005
</style>
