<template>
  <div class="law-cell-comp">
    <div class="header">
      <van-checkbox :value="true" shape="square" @click="emitChange" />
      <div
        class="name van-multi-ellipsis--l3"
        :class="{
          'bold-text': type !== 'deem' && type !== 'focus'
        }"
      >
        {{ cellData.name || '' }}
      </div>
    </div>
    <div class="extra-info">
      <template v-if="type === 'appeal'">
        <div class="van-ellipsis" v-if="cellData.case_name">
          {{ cellData.case_name }}
        </div>
        <div class="van-ellipsis" cellData.court_name>
          {{ cellData.court_name
          }}<span v-if="cellData.pub_date">/ {{ cellData.pub_date }}</span>
        </div>
      </template>
      <template v-if="type !== 'appeal'">
        <div class="van-ellipsis" v-if="cellData.case_name">
          {{ cellData.case_name }}
        </div>
        <div class="van-ellipsis" v-if="cellData.case_id">
          {{ cellData.case_id }}
        </div>
      </template>
    </div>
    <template v-if="isLawAndHasChildren">
      <div class="children-list">
        <law-child
          :type="type"
          v-for="c in getChildrenData(cellData.children)"
          :key="c.id"
          :cellData="c"
          @change="emitChildChange"
        />
      </div>
    </template>
    <template v-else>
      <div class="content van-multi-ellipsis--l3">
        <span>{{ cellData.content || '' }}</span>
      </div>
    </template>
  </div>
</template>

<script>
import LawChild from './lawChild'

export default {
  name: 'law-cell',
  props: {
    isDeleted: Boolean,
    type: String,
    cellData: Object
  },
  components: {
    LawChild
  },
  data() {
    return {}
  },
  computed: {
    isLawAndHasChildren() {
      const isLaw = this.type === 'judgment' || this.type === 'appeal'
      const hasChildren =
        this.cellData.children && this.cellData.children.length > 0
      return isLaw && hasChildren
    }
  },
  methods: {
    // 计算出子级数据 根据是否是被删除的页面筛选
    getChildrenData(arr) {
      return arr.filter(item => item.deleted === this.isDeleted)
    },
    emitChildChange(payload) {
      const data = {
        parent: this.cellData,
        self: payload
      }
      return this.$emit('change', data)
    },
    emitChange(payload) {
      this.$emit('change', this.cellData)
    }
  }
}
</script>

<style lang="stylus" scoped>
.law-cell-comp
  position relative
  margin-bottom 10px
  &:first-child
    margin-top 10px
  & .header
    display flex
    align-items flex-start
    padding 0 6px
    & .name
      flex 1
      margin-left 10px
      font-size 14px
      &.bold-text
        font-weight bold
  & .content
    padding  5px 10px
    font-size 13px
    color #555
  & .children-list
    margin-top 6px
    & .content
      padding 0 6px
      & span
        font-size 13px
  & .extra-info
    font-size 9px
    color #999
    display flex
    padding 2px 10px 0 36px
    justify-content space-between
    overflow hidden
</style>
